.tile-gallery__zoom-btn {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    border: none;
    font-size: calc(22rem/16);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    position: absolute;
    top:50%;
    z-index: 2;
    left:50%;
    transform: translate(-50%, -50%) scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity:0;
    transition: transform 0.2s ease, opacity 0.2s ease;
}
.tile-gallery-item {
    position: relative;
}
.tile-gallery-item:hover .tile-gallery__zoom-btn {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.tile-gallery__container {
    @media screen and (min-width: 768px) {
        overflow: visible;
    }
}
.tile-gallery__outer-wrapper {
    position: relative;
    @media screen and (max-width: 767px) {
        padding-left: calc(12rem/16);
    }
}
.tile-gallery__outer-wrapper:before {
    content:'';
    position: absolute;
    z-index: 9;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.71) 33.98%, rgba(247,243,239,0.93) 55.74%, rgba(247,243,239,0.94) 71.79%, #F7F3EF 100%);
    width: calc(200rem/16);
    opacity: 0.9;
    height: 100%;
    right:0;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.tile-gallery__outer-wrapper:after {
    content:'';
    position: absolute;
    z-index: 9;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.71) 33.98%, rgba(247,243,239,0.93) 55.74%, rgba(247,243,239,0.94) 71.79%, #F7F3EF 100%);
    width: calc(200rem/16);
    opacity: 0.9;
    height: 100%;
    left:0;
    top:0;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
@media screen and (min-width: 768px) {
    .tile-gallery__wrapper .scrolling-row__item:first-of-type {
        margin-left: calc(100vw / 5);
    }
    .tile-gallery__wrapper .scrolling-row__item:last-of-type {
        padding-right: calc(100vw / 5);
    }
    .tile-gallery-item {
        width: calc(100vw / 5);
    }
}
.tile-gallery__btn {
    background-color: #fff;
    z-index: 3;
    border: none;
    border-radius: 50%;
    width:calc(60rem/16);
    height: calc(60rem/16);
    padding: calc(4rem/16) 0 0;
    font-size: calc(15rem/16);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);
    pointer-events: initial;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.tile-gallery__btn--prev {
    transform: scale(-1);
}
.tile-gallery__btn.scrolling-row__button--disabled {
    opacity:0.5;
    pointer-events: none;
}
.tile-gallery__navigation {
    position: absolute;
    width: 100%;
    pointer-events: none;
    top: 50%;
    padding: 0 calc(20rem/16);
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 999;
}
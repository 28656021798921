.custom-checkbox {
    position: relative;
    padding-left: calc(26rem / 16);
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    top: calc(2rem / 16);
    overflow: hidden;
    border: 1px solid var(--color-dark-grey);
    background: var(--color-light-grey);
    width: 1rem;
    height: 1rem;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(12rem / 16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text a {
    text-decoration: underline;
}

.custom-checkbox-button {
    padding-left:0;
}
.custom-checkbox-button > label {
    width: 100%;
}
.custom-checkbox-button .custom-checkbox__text {
    height: auto;
    min-height: calc(1.5em + 1.74rem + 2px);
    padding: .7rem 1.56rem;
    display: flex;
    align-items: center;
}
.custom-checkbox-button .icon {
    font-size: calc(22rem/16);
}

.custom-checkbox-button .custom-checkbox__input:checked ~ .custom-checkbox__text{
    background-color: var(--color-primary);
}
/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}
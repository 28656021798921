.contact-box {
    background-color: var(--color-secondary);
    color:#fff;
    padding: calc(50rem/16) calc(80rem/16);
    font-size: calc(16rem/16);
    line-height: calc(28/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        padding: calc(30rem/16) calc(20rem/16);
        line-height: calc(20/14);
    }
}
.contact-box-external-links {
    padding: calc(50rem/16) calc(80rem/16);
    font-size: calc(16rem/16);
    line-height: calc(28/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        padding: calc(30rem/16) calc(20rem/16);
        line-height: calc(20/14);
    }
}
.contact-box__logo {
    margin-bottom: calc(35rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(100rem/16);
        margin-bottom: calc(10rem/16);
    }
}
.contact-box__title {
    text-transform: uppercase;
    font-size: calc(22rem/16);
    margin-bottom: calc(10rem/16);
    font-family: var(--font-decorative);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.contact-box__link {
    display: block;
    margin-bottom: calc(5rem/16);
}

.contact-box__link:hover {
    color: var(--color-primary-light);
}

.contact-box__link--external{
    color: var(--color-primary-light);
}
.contact-box__link--external:hover{
    text-decoration: underline;
}

.contact-box__link-icon {
    width: calc(30rem/16);
    font-size: calc(15rem/16);
}
.contact-box__address {
    margin-bottom: calc(20rem/16);
}
.accordion-item {
    background-color: var(--color-secondary);
    color:#fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.3);
}
.accordion-item__header-link {
    display: block;
    padding: 1rem calc(15rem/16);
    line-height:1;
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) calc(30rem/16) calc(23rem/16);
    }
}
.accordion-item__toggle {
    position: relative;
    color: #fff;
    font-size: calc(30rem / 16);
    display: flex;
    padding-right: calc(10rem / 16);
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        font-size: calc(10rem / 16);
        padding-right: calc(5rem/16);
    }
}
.accordion-item__title {
    padding: 0 calc(0rem / 16);
    margin-bottom: 0;
    line-height: 1.4;
    font-size: calc(18rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}

.accordion-item__body {
    padding: calc(0rem / 16) calc(30rem/16) calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        padding: 0 calc(15rem/16) calc(20rem/16);
    }
}

.accordion-item__toggle-icon {
    font-size: calc(20rem/16);
    transition: transform 0.3s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(9rem/16);
    }
}

.accordion-item__header-link:not(.collapsed) .accordion-item__toggle-icon {
    transform: rotate(180deg);
}
.accordion-item + .accordion-item {
    margin-top: calc(15rem/16);
}
.accordion-item .wysiwyg a {
    color: var(--color-primary-light-middle);
}
.accordion-item .wysiwyg a:hover {
    color: var(--color-primary-light-light);
}
.references {
    color: var(--color-primary);
    margin-bottom: calc(30em/16);
}
.references__header {
    display: flex;
}
.references__body {
    margin-top: calc(12rem/16);
    margin-left: calc(50rem/16);
    border-left: 1px solid var(--color-primary);
    @media screen and (max-width: 767px) {
        margin-left: calc(12rem/16);
    }
}
.references__icon {
    font-size: calc(30rem/16);
    margin-right: calc(15rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
        margin-right: calc(12rem/16);
    }
}
.references__title {
    font-family: var(--font-decorative);
    font-size: calc(22rem/16);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.title-block {
    max-width: calc(945rem/16);
}
.title-block__title {
    color: var(--color-primary);
    @media screen and (max-width: 767px){
        font-size: calc(16rem/16);
    }
}
.title-block--white .title-block__title {
    color:#fff;
}
.title-block--white .title-block__wysiwyg {
    color:#fff;
}
.title-block__sub-title {
    color: var(--color-primary);
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
    }
}
.title-block__sub-title--small {
    font-size: calc(14rem/16);
    text-transform: none;
    font-family: var(--font-default);
}
.title-block--white .title-block__sub-title {
    color:#fff;
}
.title-block--centered {
    text-align: center;
    margin: 0 auto;
}
.title-block {
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.title-block--small-spacing {
    margin-bottom: calc(25rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(11rem/16);
    }
}
.title-block__wysiwyg {
    margin-top: calc(20rem/16);
    max-width: calc(947rem/16);
}
.title-block__wysiwyg--small {
    max-width: calc(774rem/16);
}
.title-block--centered .title-block__wysiwyg {
    margin: calc(20rem/16) auto 0;
}
.title-block--splitted .title-block__wysiwyg {
    margin-top: 0;
}
.title-block--splitted {
    margin: 0 auto calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(35rem/16);
    }
}
.title-block__big-title {
    font-size: calc(80rem/16);
    font-family: var(--font-decorative);
    color: var(--color-primary-light);
    line-height:0.9;
    margin-bottom:0;
    @media screen and (min-width: 1400px){
        font-size: calc(90rem/16);
    }
    @media screen and (max-width: 767px){
        font-size: calc(40rem/16);
    }
}
.title-block--splitted .title-block__big-title {
    @media screen and (max-width: 767px) {
        font-size: calc(35rem / 16);
    }
}
.title-block__small-title {
    font-size: calc(28rem/16);
}
.title-block__icon {
    font-size: calc(40rem/16);
    color: var(--color-primary);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px){
        font-size: calc(25rem/16);
        margin-bottom: calc(10rem/16);
    }
}

.title-block--shadow .title-block__sub-title {
    text-shadow: 0 calc(1rem / 16) calc(4rem / 16) rgba(0,0,0,0.8);
}
.title-block--shadow .title-block__title {
    text-shadow: 0 calc(2rem / 16) calc(10rem / 16) rgba(0,0,0,0.5);
}

@media screen and (min-width: 768px) {
    .title-block--move-up {
        margin-top: calc(-120rem/16);
    }
    .title-block--with-contact .title-block {
        padding-top: calc(80rem/16);
    }
}

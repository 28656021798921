.weather-item {
    margin: 0 auto;
    max-width: calc(130rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(166rem / 16);
    }
}
.weather-item__title {
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}
.weather-item__val {
    position: relative;
    font-size: calc(13rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}
.weather-item__val + .weather-item__val {
    padding-left: calc(8rem / 16);
    margin-left: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(20rem / 16);
        padding-left: calc(20rem / 16);
    }
}
.weather-item__val + .weather-item__val:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(2rem / 16);
    background: currentColor;
    opacity: .6;
}
.weather-item__img {
    max-width: calc(46rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(85rem / 16);
    }
}
.weather-item--sm {
    max-width: none;
    @media screen and (min-width: 768px) {
        max-width: none;
    }
}
.weather-item--sm .weather-item__img {
    max-width: calc(42rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(50rem / 16);
    }
}

.weather-item--md .weather-item__val {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem / 16);
    }
}
.weather-item__detail-icon {
    font-size: calc(18rem / 16);
    margin-right: calc(3rem / 16);
    vertical-align: -.2em;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        margin-right: calc(5rem / 16);
    }
}
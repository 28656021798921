.timeline-wrapper {
    margin-top: calc(50rem/16);
    position: relative;
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
}
.timeline-wrapper:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    background-color: var(--color-primary);
    width: calc(1rem/16);
    left: 0;
    right: 0;
    height: 100%;
    margin: 0 auto;
}
.timeline-row+.timeline-row {
    margin-top: -1.25rem;
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
}
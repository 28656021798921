.tour-map {
    z-index: 1;
}

.tour-info__value {
    font-size: calc(18rem / 16);
    line-height: calc(30/22);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}

.tour-detail__info-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: calc(8rem / 16);
}

.tour-detail__status {
    display: inline-block;
}

.tour-detail__status-icon {
    /*width: 2.5rem;*/
    /*height: 2.5rem;*/

    font-size: calc(18rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);

    @media screen and (max-width: 767px) {
        /*width: 1.5rem;*/
        /*height: 1.5rem;*/
        font-size: calc(12rem/16);
    }
}

.tour-detail__status-text {
    font-size: calc(10rem/16);
    color:#fff;
    padding-left: calc(5rem/16);
    display: inline-flex;
    align-items: center;
    text-shadow:0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.3);
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.tooltip.tour-status__tooltip .tooltip-inner {
    max-width: 500px !important;
}
.btn-rounded {
    border-radius: calc(30rem / 16);
}
.btn-link {
    color: var(--color-primary-light);
    text-decoration: underline;
    padding: 0;
    text-transform: none;
    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-default);
    letter-spacing: normal;
}
.btn-icon {
    font-size: calc(16rem/16);
    vertical-align: calc(-3rem/16);
}

/* colors */
.btn-white {
    color: var(--color-primary);
}
.btn-white:hover,
.btn-white:focus,
.btn-white:focus-visible,
.btn-white:active,
.btn-white:not(:disabled):not(.disabled):active {
    background: var(--color-primary-light);
    border-color: #fff;
    color: #fff;
    box-shadow: none;
}
.btn-primary:hover {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
}
.btn-primary-light:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.btn-outline-primary:hover {
    background-color: var(--color-primary);
    color:#fff;
}
.btn-outline-white:focus, .btn-outline-white:active {
    color:#fff;
}
.btn-quickfilter {
    background-color:#fff;
    padding: calc(5rem/16) calc(10rem/16);
    border-radius: calc(12rem/16);
    font-size: calc(13rem/16);
    box-shadow: none;
    border: none;
    display: inline-block;
    margin-bottom: calc(5rem/16);
    margin-right: calc(5rem/16);
}
.btn-quickfilter .icon {
    pointer-events: none;
}
.btn-icon-text {
    display: flex;
    align-items: center;
}
.btn-icon-text__icon {
    margin-right: calc(10rem/16);
}
.btn-icon-text__icon--right {
    margin-right: auto;
    margin-left: calc(10rem/16);
}
.btn-info {
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 100%;
    background-color: var(--color-primary-light);
    color:#fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    border: none;
}

a.btn.btn-primary-light {
    color: #fff;
}

.btn.btn-square{
    width: calc(38rem/16);
    height: calc(40rem/16);
    padding: calc(5rem/16);

    @media screen and (min-width: 768px){
        width: calc(46rem/16);
        height: calc(46rem/16);
    }
}
.range-slider__values {
    font-size: calc(14rem / 16);
    color: var(--color-dark-grey);
}
.range-slider__slider {
    margin: calc(18rem / 16) 0;
    height: calc(3rem / 16);
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: var(--color-dark-grey);
    padding: 0 calc(12rem / 16);
}
.range-slider__slider .noUi-handle {
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    background: var(--color-primary-light);
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    top: calc(-11rem / 16);
    border: 0;
}
.range-slider__slider .noUi-handle:before,
.range-slider__slider .noUi-handle:after {
    content: none;
}
.range-slider__slider .noUi-handle:focus {
    outline: none;
}
html:not([dir=rtl]) .range-slider__slider.noUi-horizontal .noUi-handle {
    right: calc(-12rem / 16);
}
.range-slider__slider .noUi-connect {
    background: var(--color-primary-light);
    box-shadow: none;
    height: calc(3rem / 16);
}

.range-slider__input {
    background: none;
    border: 0;
    color: currentColor;
    cursor: default;
}
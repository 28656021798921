.hotspot {
    position: absolute;
    margin-left: calc(-20rem / 16);
    margin-top: calc(-20rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(-30rem / 16);
        margin-top: calc(-30rem / 16);
    }
}
.hotspot.is-open {
    z-index: 1;
}
.hotspot__btn {
    border: none;
    background: none;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    padding: calc(8rem / 16);
    border-radius: 50%;
    background: rgba(2, 131, 55, .5);
    color: #fff;
    transition: background 200ms ease-in-out, transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
    }
}
.hotspot__btn--small {
    width: calc(33rem / 16);
    height: calc(33rem / 16);
    padding: calc(8rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
    }
}
.hotspot__btn--small:before {
    content:var(--icon-plus);
    font-family: iconfont;
    position: absolute;
    font-size: calc(8rem/16);
    top: 50%;
    color: Var(--color-primary);
    left: 50%;
    width: calc(15rem/16);
    height: calc(15rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.7;
    transform: translateX(-50%) translateY(-50%);
    @media screen and (min-width: 768px) {
        width: calc(20rem/16);
        height: calc(20rem/16);
        font-size: calc(11rem/16);
    }
}
.hotspot__btn:hover {
    background: rgb(54, 146, 92);
    transform: scale(1.1);
}
.hotspot__btn__value {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%) translateX(calc(-10rem / 16));
}

.hotspot__popover {
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #202020 0%, rgba(32,32,32,0.8) 100%);
    color: #fff;
    padding: calc(20rem / 16);
    visibility: hidden;
    opacity: 0;
    width: 100%;
    transform: translateY(100%);
    transform-origin: bottom center;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, visibility 200ms;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        bottom: auto;
        padding: calc(24rem / 16) calc(40rem / 16);
        position: absolute;
        transform: translateX(-50%) translateY(-95%) scale(.97);
        left: 50%;
        top: calc(-6rem / 16);
        width: calc(340rem / 16);
    }
}
.is-open .hotspot__popover {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;

    @media screen and (min-width: 768px) {
        transform: translateX(-50%) translateY(-100%) scale(1);
    }
}
.hotspot__popover:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    border-style: solid;
    border-width: calc(14rem / 16) calc(14rem / 16) 0 calc(14rem / 16);
    border-color: rgba(32,32,32,0.8) transparent transparent transparent;
}

.hotspot__popover .hotspot__popover__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: calc(10rem / 16);
}

/* plain style */
.hotspot.hotspot--plain {
    transform: translateY(-50%) translateX(-50%);
    margin: 0;
}
.hotspot--plain .hotspot__btn {
    background: none;
    padding: 0;
    width: calc(60rem / 16);
    height: auto;
}
.main-navbar__toggle {
    border: none;
    box-shadow: none;
    width: calc(78rem/16);
    height: 100%;
    background-color: var(--color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    outline:none;
    @media screen and (max-width: 767px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
    }

    @media screen and (min-width: 1500px){
        width: calc(118rem/16);
    }
}
.main-navbar__toggle:hover, .main-navbar__toggle:active, .main-navbar__toggle:focus {
    outline:none;
}
.main-navbar__toggle__text {
    color:#fff;
    opacity: 0.2;
    text-transform: uppercase;
    letter-spacing: calc(3.5rem/16);
    font-family: var(--font-decorative);
    font-size: calc(14rem/16);
    position: absolute;
    transform: translateY(0%);
    bottom: calc(14rem/16);
    transition: opacity 0.2s ease, transform 0.1s ease;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.main-navbar__toggle:hover:not(.is-open) .main-navbar__toggle__text {
    opacity:1;
}
.main-navbar__toggle__text--open {
    opacity:0;
    text-transform: uppercase;
    letter-spacing: calc(3.5rem/16);
    font-family: var(--font-decorative);
    font-size: calc(14rem/16);
    position: absolute;
    bottom: calc(14rem/16);
    transform: rotate(-90deg) translateX(-100%);
    color: #fff;
    top: calc(158rem/16);
    left: calc(-30rem/16);
    line-height: 1;
    transition: opacity 0.2s ease, visibility 0.0001s ease, transform 0.3s ease;
    visibility:hidden;
    @media screen and (max-width: 767px) {
        display:none;
    }

    @media screen and (min-width: 1500px){
        left: calc(-8rem/16);
    }
}
.is-open .main-navbar__toggle__text--open {
    opacity: 0.2;
    transform: rotate(-90deg) translateX(0%);
    visibility: visible;
}

html[lang="en"] .is-open .main-navbar__toggle__text--open{
    @media screen and (min-width: 768px){
       left: calc(19rem/16);
        top: calc(125rem/16);
    };
}
.main-navbar__toggle.is-open:hover .main-navbar__toggle__text--open {
    opacity:1;
}
.is-open .main-navbar__toggle__text {
    opacity:0;
    transform: translateY(100%);
}
.main-navbar__toggle__line {
    background-color: #fff;
    border-radius: 10px;
    width: calc(24rem/16);
    height: calc(4rem/16);
    margin-bottom: calc(6rem/16);
    transition: transform 0.2s ease;
}
.main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(1), .main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(3) {
    transform: translateX(-2px);
}
.main-navbar__toggle:hover .main-navbar__toggle__line:nth-child(2) {
    transform: translateX(2px);
}
.main-navbar__toggle__line:nth-child(1) {
    margin-left: calc(-5rem/16);
    transition: transform 0.2s ease;
}
.main-navbar__toggle__line:nth-child(2) {
    margin-left: calc(5rem/16);
    opacity:1;
    transition: opacity 0.1s ease, transform 0.2s ease;
}
.main-navbar__toggle__line:nth-child(3) {
    transition: transform 0.2s ease;
    margin-left: calc(-5rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}
.main-navbar__toggle {
    z-index: 999;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(1) {
     animation-timing-function: cubic-bezier(1,0,.26,.64);
     animation: 0.3s forwards firstLine;
     transform-origin: center;
}

.main-navbar__toggle.is-not-open .main-navbar__toggle__line:nth-child(1) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards firstLine-reverse;
    transform-origin: center;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(2) {
    transition: opacity 0.2s ease-in-out;
    opacity:0;
}
.main-navbar__toggle.is-open .main-navbar__toggle__line:nth-child(3) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards lastLine;
}
.main-navbar__toggle.is-not-open .main-navbar__toggle__line:nth-child(3) {
    animation-timing-function: cubic-bezier(1,0,.26,.64);
    animation: 0.3s forwards lastLine-reverse;
    transform-origin: center;
}
@keyframes firstLine {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    70% {
        transform: translateY(10px) rotate(45deg) scaleX(1.1);
    }
    100% {
        transform: translateY(10px) rotate(45deg);
    }
}

@keyframes firstLine-reverse {
    0% {
        transform: translateY(10px) rotate(45deg);
    }
    70% {
        transform: translateY(0) rotate(0deg) scaleX(1.1);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}


@keyframes lastLine {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    70% {
        transform: translateY(-10px) rotate(-45deg) scale(1.1)
    }
    100% {
        transform: translateY(-10px) rotate(-45deg);
    }
}

@keyframes lastLine-reverse {
    0% {
        transform: translateY(-10px) rotate(-45deg);
    }
    70% {
        transform: translateY(0) rotate(0deg) scale(1.1);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}

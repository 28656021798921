.lg-backdrop {
    background-color: #464646;
}

.lg-toolbar, .lg-actions .lg-prev, .lg-actions .lg-next {
    background-color:transparent;
}

.lg-sub-html {
    background-color:transparent;
    font-size:calc(15rem/16);
    font-family:var(--font-default);
    color: #fff;
    text-shadow:0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.3);
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}
.lg-actions .lg-prev, .lg-actions .lg-next {
    font-size:calc(25rem/16);
    padding:calc(15rem/16) calc(20rem/16);
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        top:48%;
    }
}

.lg-toolbar .lg-close, .lg-actions .lg-next, .lg-actions .lg-prev {
    display: inline-block;
    font-family: iconfont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    background-color:#fff;
    color: var(--color-primary-light);
    transform: scale(1.1);
}
.lg-actions .lg-prev:hover {
    transform: scale(-1.1);
}
.lg-toolbar .lg-close:hover {
    color:var(--color-primary-light);
}

.lg-toolbar .lg-close {
    right: 20px;
    top: 20px;
    background-color: #fff;
    width: 4.375rem;
    height: 4.375rem;
    z-index: 9;
    box-shadow: none;
    border: none;
    color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all .2s ease;
    margin-top:calc(20rem/16);
    margin-right: calc(20rem/16);
}
.lg-toolbar .lg-close:after {
    content: var(--icon-close);
}
.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
}
.lg-actions .lg-prev {
    transform: scale(-1);
}
.lg-actions .lg-prev:after {
    content: var(--icon-arrow-right);
}
.lightbox__zoom-btn {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    border: none;
    font-size: calc(22rem/16);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    position: absolute;
    top:50%;
    z-index: 2;
    left:50%;
    transform: translate(-50%, -50%) scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity:0;
    transition: transform 0.2s ease, opacity 0.2s ease;
}
.lightbox-item {
    position: relative;
}
.lightbox-item:hover .lightbox__zoom-btn {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.lg-outer video, .lg-outer iframe {
    width: calc(845rem/16);
    height: calc(475rem/16);
    @media screen and (max-width: 767px) {
        width: 100%;
        height:auto;
    }
}
.lg-outer .lg-video {
    padding-bottom: 60.25% !important;
}
.lg-outer .lg-video iframe {
    background-color:#fff;
}

.season-info {

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.season-info__item {
    width: 33%;
    color: var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        width: 100%;
    }
}
.season-info__item.is-recommended {
    color: var(--color-primary-light);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

.season-info__item:not(:first-child) {
    @media screen and (min-width: 768px) {
        margin-left: calc(2rem / 16);
    }
}
.season-info__item__state {
    height: calc(16rem / 16);
    width: calc(16rem / 16);
    margin-right: calc(6rem / 16);
    background: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        margin-right: 0;
        height: calc(8rem / 16);
        width: 100%;
    }
}
.season-info__item.is-recommended .season-info__item__state {
    background: var(--color-primary-light);
}
html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--body-bg-color);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.body--freezed {
    overflow: hidden;
}

#main-content {
    @media screen and (max-width: 767px) {
        padding-top: calc(60rem/16);
    }
}
.main-content--no-hero {
    padding-top: calc(170rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(70rem/16);
    }
}
.main-content--no-hero-small {
    padding-top: calc(118rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(120rem/16);
    }
}
.main-header--with-meta-link ~ .main-content--no-hero-small {
    padding-top: calc(149rem / 16);
}

.test-z{
    color: blue;
}
@media (hover: none) {
    .mega-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .mega-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}

.mega-nav__item-content--toggle {
    display: none;
}
.mega-nav__teaser-list {
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(0rem/16);
        justify-content:center;
    }
}
.mega-nav__container {
    padding-left: 10%;
    width: 89%;
    @media screen and (max-width: 1699px)  and (min-width: 768px){
        padding-left: 10%;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        width: 100%;
    }
    
    @media screen and (min-width: 768px){
       padding-left: 0;
    }
}

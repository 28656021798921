.hero-lp {
    width: 100%;
    max-height: 80vh;
    height: 100%;
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(430rem/16);
    }
}
.hero-lp__img-wrapper {
    margin-bottom:0;
}
.hero-lp__img {
    object-fit: cover;
}
.hero-lp__embed {
    height: calc(700rem/16);
    max-height: 80vh;
    @media screen and (max-width: 767px) {
        height: calc(430rem/16);
    }
}
.hero-lp__bg {
    height:100%;
    width: 100%;
    background-size:cover;
}
.hero-lp__bg {
    background-image: var(--image-src-xs) !important;
    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
    }
}
.hero-lp:after {
    content:'';
    position: absolute;
    height: calc(540rem/16);
    width: 100%;
    display: block;
    background: linear-gradient(180deg, rgba(32,32,32,0) 0%, rgba(32,32,32,0.7) 100%);
    z-index: 1;
    bottom:0;
    @media screen and (max-width: 767px) {
        height:65%;
    }
}
.hero-lp__content {
    z-index: 2;
    position: absolute;
    bottom: calc(65rem/16);
    margin: 0 auto;
    left:0;
    right:0;
    width: 100%;
    height: auto;
    color:#fff;
    text-align: center;
    @media screen and (max-width: 767px) {
        bottom: calc(30rem/16);
    }
}
.hero-lp__title {
    font-size: calc(70rem/16);
    line-height: 1.4;
    text-transform: uppercase;
    margin-top: calc(30rem/16);
    letter-spacing: calc(7.5rem/16);
    font-family: var(--font-decorative);
    background-image:linear-gradient(0deg,rgba(255,255,255,0.0),rgba(255,255,255,0.3),rgba(255,255,255,0.9),rgba(255,255,255,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color:  #fff;
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
        letter-spacing: calc(1rem/16);
        margin-top: calc(20rem/16);
        background-image:linear-gradient(0deg,rgba(255,255,255,0.3),rgba(255,255,255,0.9),rgba(255,255,255,1));
    }
}
@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, .hero-lp__title { color: #fff; background-image: none; } /* IE11 */
}
.hero-lp__sub-title {
    letter-spacing: calc(10rem/16);
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        letter-spacing: calc(5rem/16);
    }
}
.hero-lp__brand-wrapper {
    position: absolute;
    right:0;
    text-align: center;
    display: block;
    margin: 0 auto;
    left:0;
    width: 14rem;
    padding: calc(10rem/16);
    z-index: 10;

    @media(max-width: 767px){
        width: 12rem;
    }
}

.hero-lp__brand{
    @media screen and (min-width: 768px){
       max-width: calc(150rem/16);
    }
}

/*lg logo option*/
.hero-lp__brand-wrapper.hero-lp__brand-wrapper--lg .hero-lp__brand{
    @media screen and (min-width: 1200px){
        max-width: calc(250rem/16);
    }
}
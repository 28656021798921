.step-counter {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: calc(73rem/16);
    position: relative;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(30rem/16);
    }
}
.step-counter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: calc(100rem/16);
    height: calc(111rem/16);
    color: var(--color-dark-grey);
}
.step-counter-item__number {
    width: calc(60rem/16);
    height: calc(60rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(28rem/16);
    font-family: var(--font-decorative);
    border: 1px solid var(--color-dark-grey);
    border-radius: 50%;
    background-color: var(--color-dark-bg);
    z-index: 1;
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
        font-size: calc(20rem/16);
    }
}
.step-counter-item.is-current {
    color: white;
}
.step-counter-item.is-current .step-counter-item__number {
    color: var(--color-dark-bg);
    background-color: white;
    border: 1px solid white;
}
.step-counter-item.is-checked {
    color: white;
}
.step-counter-item.is-checked .step-counter-item__number {
    color: white;
    background-color: var(--color-dark-bg);
    border: 1px solid white;
    position: relative;
}
.step-counter-item.is-checked .step-counter-item__number > span {
    display: none;
}
.step-counter-item.is-checked .step-counter-item__number::before {
    content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    inset: 0;
    font-size: calc(20rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.step-counter-item__text {
    font-size: calc(14rem/16);
    line-height: calc(20rem/16);
    margin-top: calc(11rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: calc(16rem/16);
        margin-top: calc(8rem/16);
    }
}
.step-counter-item__header {
    position: relative;
}
.step-counter-item__header::after {
    content: '';
    position: absolute;
    top: calc(30rem/16);
    right: 0;
    width: calc(130rem/16);
    transform: translateX(100%);
    border-top: 1px solid var(--color-dark-grey);
    @media screen and (max-width: 767px) {
        top: calc(20rem/16);
        width: calc(75rem/16);
    }
}
.step-counter > div:last-of-type .step-counter-item__header::after {
    display: none;
}
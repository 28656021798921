.video-overlay__icon {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(24rem/16);
    padding-left:calc(3rem/16);
    transition: 0.2s ease;
    transform-origin: left;
}
.video-overlay:hover .video-overlay__icon {
    transform: scale(1.2) translate(-50%, -50%);
}
.video-overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;
}
.video-overlay__wrapper {
    position: relative;
}
.video-overlay.overlay-hide {
    opacity:0;
    pointer-events: none;
}
.team-teaser {
    height: 100%;
}
.team-teaser__item{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.team-member-title {
    text-align: center;
}
.team-teaser__img-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}
.team-teaser__img {
    border-radius: 50%;
}
.team-teaser__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--color-primary);
    flex-grow: 1;
}

.team-teaser__content .team-teaser__title {
    font-weight: 600;
}
.team-headline{
    text-align: center;
    color: var(--color-primary-light);
}
.team-teaser__mail{
    white-space: nowrap;
}
.team-teaser__modal-btn {
    margin-top: 1rem;
}
.team-teaser__modal-btn .btn-primary-light:hover{
    color: var(--color-white)!important;
}
@media(min-width: 769px){
    .team-member-title {
        font-size: 2rem;
        margin-bottom: 2rem;
        padding-top: 2rem;
    }
    .team-teaser__position{
        font-size: 1.7rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    .team-headline{
        font-size: 2rem;
    }
    .team-teaser__content .team-teaser__title {
        font-size: 1.6rem;
    }
    .team-teaser.teaser-animation:hover:before {
        opacity: 0.8;
        transform: scale(1.07);
    }
    .team-teaser.teaser-animation:hover .teaser-animation__img {
        transform: scale(0.9);
    }
}
.img-text-slider__swiper-navigation {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 27.75rem;
    justify-content: flex-end;
    @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
        width: auto;
        position: relative;
    }
}
.img-text-slider__swiper-navigation--left {
    width: auto;
    margin-left: calc(20rem/16);
    right: initial;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.img-text-slider {
    position: relative;
}
.img-text-slider__btn.swiper-button--next {
    transform: translateY(-50%);
    margin-left: calc(15rem/16);

    @media screen and (min-width: 768px){
       width: calc(45rem/16);
       height: calc(45rem/16);
    }
}
.img-text-slider__btn.swiper-button--prev  {
    transform: translateY(-50%) rotate(180deg);

    @media screen and (min-width: 768px){
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}

.img-text-slider__btn.swiper-button-disabled {
    opacity:0.4;
    @media screen and (max-width: 767px) {
        opacity: 0.8;
    }
}
.img-text-slider__btn {
    transition: opacity 0.2s ease;
}
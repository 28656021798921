.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}
.loading-overlay--dark {
    background: rgba(0, 0, 0, .9);
    z-index: 1000;
}
.loading-overlay--fixed {
    position: fixed;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    width: 90%;
    height: 80%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 768px) {
        width: 60%;
    }
}
.datepicker{
    position: relative;
}
.datepicker:not(.no-icon):after{
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
}
.datepicker .form-control[readonly]{
    background-color: var(--color-secondary);
}


.datepicker-static .flatpickr-day{
    pointer-events: none;
}

.datepicker-static .flatpickr-day.flatpickr-disabled,
.datepicker-static .flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: rgba(57,57,57,.25);
}

.datepicker-static .flatpickr-day:not(.flatpickr-disabled){
    background-color: var(--color-primary-light);
    color: #fff;
}


.datepicker-static .flatpickr-day:hover{
    background-color: transparent;
}
.hero-has-shadow:after{
    content: "";
    position: absolute;
    height: 33.75rem;
    width: 100%;
    display: block;
    background: linear-gradient(180deg,rgba(32,32,32,0),rgba(32,32,32,.7));
    z-index: 1;
    bottom: 0;
}

.countdown{
    text-align: center;
}

.countdown-height-wrapper{
    min-height: calc(92rem/16);
}

.countdown-wrapper{
    display: flex;
    text-align: center;
    justify-content: center;
}


.hero .countdown{
   /* position: absolute;
    bottom: calc(50rem/16);
    left: 50%;
    transform: translateX(-50%);*/
    z-index: 2;
    width: 100%;
}

.countdown__item{
    padding:  calc(12rem/16) 0;
    background-color: rgba(255,255,255,0.5);
    border-radius:  calc(5rem/16);
    margin: 0 calc(5rem/16);
    width: calc(78rem/16);
    text-align: center;
}

.countdown__item-figure{
    background-color: var(--color-primary);
    font-size: calc(27rem/16);
    border-radius: calc(5rem/16);
    font-family: var(--font-default-bold);
    color: #fff;
    width: calc(43rem/16);
    display: inline-block;
    white-space: nowrap;
}

.countdown__item-text{
    font-size: calc(14rem/16);
    margin-top: calc(5rem/16);
}

.hero__title-block{
    margin-bottom: calc(30rem/16);
}

.hero__title{
    font-size: calc(40rem/16);
    line-height: 1.15;
    text-transform: uppercase;
    padding-top: 1.875rem;
    letter-spacing: .46875rem;
    font-family: var(--font-decorative);
    background-image: linear-gradient(0deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.3),hsla(0,0%,100%,.9),#fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    
    @media screen and (min-width: 768px){
        font-size: 7.8125rem;
    }
}

.hero__subtitle{
    letter-spacing: calc(20rem/16);
    font-size: calc(17rem/16);
    color: #fff;
    margin-top: calc(5rem/16);
    
    @media screen and (min-width: 768px){
        letter-spacing: .625rem;
        font-size: 1.25rem;
    }
}

.countdown__finished-text{
    padding: calc(10rem/16) calc(20rem/16);
    background-color: rgba(255,255,255,0.5);
    display: inline-block;
    border-radius: 5px;
    font-size: calc(18rem/16);
    letter-spacing: calc(4rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.hide{
    display: none;
}
.wysiwyg-area__buttons {
    padding-top: calc(40rem/16);
}
.wysiwyg-area__image {

}
.wysiwyg-gallery__zoom-btn {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    color: var(--color-primary);
    border-radius: 50%;
    border: none;
    font-size: calc(22rem/16);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    position: absolute;
    top:50%;
    z-index: 2;
    left:50%;
    transform: translate(-50%, -50%) scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity:0;
    transition: transform 0.2s ease, opacity 0.2s ease;
}
.wysiwyg-gallery-item {
    position: relative;
}
.wysiwyg-gallery-item:hover .wysiwyg-gallery__zoom-btn {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
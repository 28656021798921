.quote-area__text {
    font-size: calc(32rem/16);
    font-family: var(--font-decorative);
    color: var(--color-primary-dark);
    padding: calc(30rem/16) calc(40rem/16);
    text-align:center;
    border-top: calc(1rem/16) solid var(--color-primary-dark);
    border-bottom: calc(1rem/16) solid var(--color-primary-dark);
    display: inline-flex;
    max-width: calc(700rem/16);
    margin-bottom:0;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(30rem/16);
        font-size: calc(20rem/16);
        display: flex;
        justify-content: center;
    }
}
.quote-area--small .quote-area__text {
    font-size: calc(22rem/16);
    font-family: var(--font-default);
    padding: calc(25rem/16) 0;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        justify-content: center;
    }
}
.quote-area {
    text-align:center;
}
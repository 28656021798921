.facts-area__img-wrapper {
    text-align:center;
    margin-bottom: calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.facts-box {
    padding: calc(40rem/16) calc(50rem/16);
    color:#fff;
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(10rem/16);
    }
}
.facts-box--primary-light {
    background-color: var(--color-primary-light);
}
.facts-box--green {
    background-color: var(--color-green);
}
.facts-box__title {
    text-transform: uppercase;
    font-size: calc(22rem/16);
    text-align: center;
    font-family: var(--font-decorative);
    margin-bottom: calc(24rem/16);
}
.facts-box__list-item {
    background-color:#fff;
    color: var(--color-primary);
    padding: calc(14rem/16) calc(20rem/16);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
    margin-bottom: calc(10rem/16);
    display: flex;
    font-size: calc(16rem/16);
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
    }
}
.facts-box__icon {
    width: calc(40rem/16);
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        width: calc(30rem/16);
        font-size: calc(16rem/16);
    }
}
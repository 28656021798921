.content-teaser__embed--landscape {
    padding-top:  55.07%;
    @media screen and (max-width: 767px) {
        padding-top: calc( 134 / 169 * 100%);
    }
}
.content-teaser__embed--square {
    padding-top: calc( 535 / 582 * 100%);
}
.content-teaser {
    overflow: hidden;
    position: relative;
}
.content-teaser__content {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    color:#fff;
    padding: calc(26rem/16);
    display: flex;
    z-index: 2;
    align-items: flex-end;
}
.content-teaser__badge {
    background-color: #fff;
    margin-bottom: calc(15rem/16);
    color: var(--color-primary);
    padding: calc(3rem/16) calc(8rem/16);
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    display: inline-block;
}
.content-teaser--landscape .content-teaser__content {
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.content-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
    @media screen and (max-width: 767px){
        height: 100%;
    }
}
.content-teaser__title {
    margin-bottom:0;
    margin-top: calc(8rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        margin-top: calc(0rem/16);
    }
}
.content-teaser--landscape .content-teaser__title {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.content-teaser--square .content-teaser__title {
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.content-teaser__caption {
    position: absolute;
    font-size: calc(9rem/16);
    color:#fff;
    top:0;
    right:0;
    padding: calc(5rem/16);
    text-shadow:0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.3);
    text-align:right;
}
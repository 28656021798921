.cse__searchbar-wrapper {
    max-width: calc(550rem/16);
    margin: 0 auto;
}
@media (max-width: 767px) {
    .cse-teaser__img {
        margin-bottom: 1rem;
    }
    .cse-teaser__link{
        display: none;
    }
    .cse-teaser__img__placeholder{
        display: grid;
        justify-content: center;
    }
}

.cse-teaser__img__square{
    margin-left: 50%;
    transform: translateX(-50%);
}
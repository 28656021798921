.teaser-animation {
    backface-visibility: hidden;
}
.teaser-animation__img {
    transition: transform 0.4s ease;
}
.teaser-animation:before {
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: bottom;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
@media screen and (min-width: 768px) {
    .teaser-animation:hover:before {
        opacity: 0.8;
        transform: scale(1.6);
    }
    .teaser-animation:hover .teaser-animation__img {
        transform: scale(1.07);
    }
}

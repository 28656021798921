.nav-teaser__embed {
    padding-top: calc( 150 / 216 * 100%);
}
.nav-teaser {
    border-radius: calc(5rem/16);
    overflow: hidden;
    position: relative;
    box-shadow: 0 20px 40px 0 rgba(32,32,32,0.6);
}
.nav-teaser__content {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    color:#fff;
    padding: calc(10rem/16);
    display: flex;
    z-index: 2;
    align-items: flex-end;
}
.nav-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-1rem/16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
.nav-teaser__title {
    margin-bottom:0;
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
       font-size: calc(16rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
    }
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
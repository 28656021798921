.nav-tabs {
    border: 0;
    flex-wrap: nowrap;
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(45rem / 16);
    }
}
.nav-tabs--full-width.nav-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:0;
    border-top: calc(1rem/16) solid var(--color-primary-dark);
    border-bottom: calc(1rem/16) solid var(--color-primary-dark);
}
.nav-tabs--full-width.nav-tabs--light {
    border-color: var(--color-grey);
}
.nav-tabs .nav-item {
    flex-shrink: 0;
}
.nav-tabs .nav-link {
    border: 0;
    border-top: calc(1rem/16) solid var(--color-primary-dark);
    border-bottom: calc(1rem/16) solid var(--color-primary-dark);
    font-size: calc(12rem / 16);
    letter-spacing: calc(1.2rem / 16);
    position: relative;
    padding: calc(12rem / 16) calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        letter-spacing: calc(2.36rem / 16);
        padding: calc(16rem / 16) calc(50rem / 16);
    }
}
.nav-tabs--small .nav-link{
    letter-spacing: calc(1rem/16);
    padding: calc(10rem/16) calc(15rem/16);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
        color:#fff;
        padding: calc(10rem/16) calc(10rem/16);
    }
}

.nav-tabs--light .nav-link, .nav-tabs--light .nav-link.active, .nav-tabs--light .nav-link:hover {
    border-color: transparent;
}
.nav-tabs .nav-link:hover {
    color: var(--color-primary-light);
}
.nav-tabs .nav-link.active {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary-light);
}
.nav-tabs--small.nav-tabs .nav-link.active {
    color:#fff;
}
.nav-tabs .nav-link:after {
    content: '';
    position: absolute;
    bottom: calc(-1rem / 16);
    left: 0;
    right: 0;
    height: calc(4rem / 16);
    background: currentColor;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}
.nav-tabs .nav-link.active:after {
    transform: scaleY(1);
    opacity: 1;
    background-color: var(--color-primary-light);
}

/* tabs colors */
.nav-tabs--white .nav-link:focus,
.nav-tabs--white .nav-link:hover,
.nav-tabs--white .nav-link {
    border-color: #fff;
}
.nav-tabs--white .nav-link {
    color: #fff;
}
.nav-tabs--white .nav-link.active {
    color: #fff;
    border-color: #fff;
}

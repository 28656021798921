.main-navbar__overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    top:0;
    width: 100%;
    background: linear-gradient(180deg, #202020 0%, rgba(32,32,32,0.8) 100%);
    left:0;
    right:0;
    z-index: -1;
    transform: translateY(-100%);
    color:#fff;
    transform-origin: top;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s;
    @media screen and (max-width: 767px) {
        background: var(--color-secondary);
        overflow-y: auto;
        overflow-x: hidden;
        bottom:0;
        height: 100%;
    }
}
.main-navbar__overlay--full-height {
    height: 100%;
    bottom:0;
    background: var(--color-secondary);
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: 767px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.main-navbar__overlay.show-overlay {
    opacity:1;
    visibility: visible;
    transform: translateY(0);
    z-index: 9999;
    transition: opacity 0.2s, visibility 0.0001s, transform 0.4s;
}
.main-navbar__overlay-container {
    max-width: calc(985rem/16);
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    padding-top: calc(140rem/16);
    padding-bottom: calc(60rem/16);
    margin: 0 auto;
    opacity:0;
    height: 100%;
    transform: translateY(-100%) scale(0.5);
    transition: transform 0.7s 0.1s ease-out, opacity 0.3s 0.1s ease;
    @media screen and (max-width: 767px) {
        padding: calc(95rem/16) calc(45rem/16) calc(40rem/16);
    }
}
.main-navbar__overlay-container--md {
    max-width: calc(1060rem/16);
}
.main-navbar__overlay-container--narrow {
    max-width: var(--container-narrow-max-width);
}
.show-overlay .main-navbar__overlay-container {
    opacity:1;
    transform: translateY(0) scale(1);
}
.main-nav__search-overlay__input {
    height: calc(60rem/16);
    border: 1px solid #fff;
    border-radius: calc(30rem/16);
    display: block;
    width: 100%;
    color:#fff;
    font-size: calc(16rem/16);
    background-color: transparent;
    padding: calc(20rem/16) calc(35rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .main-nav__search-overlay__input:focus {
        outline: none;
        border-width: calc(2rem/16);
    }
    .main-nav__search-overlay__input:focus + .main-nav__search-overlay__btn {
        transform: translateX(calc(416rem/16)) rotate(260deg);
        box-shadow: 0 0 calc(10rem/16) calc(5rem/16) rgba(0,0,0,0.5);
    }
    .main-nav__search-overlay__input::placeholder {
        transform: translateX(calc(50rem/16));
        transition: transform 0.2s ease;
    }
    .main-nav__search-overlay__input:focus::placeholder {
        transform: translateX(0);
    }
}
.main-nav__search-overlay__input::placeholder {
    color:#fff;
}
.main-nav__search-overlay__btn {
    width: calc(60rem/16);
    height: calc(60rem/16);
    background-color:#fff;
    font-size: calc(20rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    left:0;
    transition: transform 0.6s ease-in-out;
    @media screen and (max-width: 767px) {
        left: initial;
        right:0;
    }
}
.main-nav__search-overlay__form {
    width: calc(475rem/16);
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 767px) {
        width: auto;
    }
}
.main-navbar__overlay-close {
    position: absolute;
    left: 50%;
    top: calc(20rem/16);
    transform: translateX(-50%);
    background-color: transparent;
    color:#fff;
    text-transform: uppercase;
    font-family: var(--font-decorative);
    padding: calc(20rem/16) calc(30rem/16);
    border: none;
    z-index: 999;
    box-shadow: none;
    justify-content: center;
    display: flex;
}
.main-navbar__overlay-close__text {
    opacity:0.4;
    transition: opacity 0.2s ease;
}
.main-navbar__overlay-close__icon {
    font-size: calc(18rem/16);
    padding-right: calc(15rem/16);
}
.main-navbar__overlay-close:hover .main-navbar__overlay-close__text {
    opacity:1;
}
.main-nav__voucher-link {
    background-color: var(--color-primary-light);
    color:#fff;
    margin-top: calc(60rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    font-size: calc(16rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    line-height: 1;
    padding: calc(14rem/16) calc(15rem/16) calc(10rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.main-nav__voucher-link-icon {
    margin-bottom: calc(3rem/16);
}
.tag-link {
    font-size: calc(16rem/16);
    color: var(--color-secondary);
    text-align: center;
    padding: calc(6rem/16) calc(25rem/16);
    background-color: var(--color-dark-grey);
    border-radius: calc(15rem/16);
    transition: background-color 0.3s ease, color 0.3s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        padding: calc(4rem/16) calc(20rem/16);
    }
}
.tag-link:hover {
    transform: translateY(-4px);
    background-color:#fff;
    color: var(--color-primary);
    box-shadow: 0 0 calc(10rem/16) calc(5rem/16) rgba(0,0,0,0.5);
} 

.region-slider__item {
    width: calc(200rem/16);
    text-align: center;
    color: #fff;
    opacity: 0.7;
    transition: 0.2s ease;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    @media screen and (max-width: 1199px) and (min-width: 768px){
        width: calc(150rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(100rem/16);
    }
}
.region-slider__item:hover {
    opacity:1;
}
.region-slider {
    overflow: hidden;
    padding-top: calc(110rem/16);
    padding-bottom: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(80rem/16);
        padding-bottom: calc(10rem/16);
    }
}
.region-slider__title {
    transition: 0.3s ease;
}
.region-slider__title--active {
    opacity:0;
    line-height:1;
    transform: scale(0) translateX(calc(-300rem/16));
    transform-origin: center;
    transition: 0.4s ease;
    font-size: calc(36rem/16);
    font-family: var(--font-decorative);
    position: absolute;
    z-index: 12;
    width: calc(800rem/16);
    height: calc(80rem/16);
    display: flex;
    text-transform: uppercase;
    align-items:flex-start;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 1199px) and (min-width: 768px){
        font-size: calc(24rem/16);
        width: 100vw;
        transform: scale(0) translateX(calc(-50vw + 70rem/16));
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        width: 100vw;
        transform: scale(0) translateX(calc(-50vw + 50rem/16));
    }
}
.swiper-slide-active .region-slider__title--active {
    opacity:1;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: bottom;
    transform: scaleX(1) translateY(calc(-50% - 100px)) translateX(calc(-300rem/16));
    transition: 0.4s 0.1s ease;
    @media screen and (max-width: 1199px) and (min-width: 768px){
        transform: scaleX(1) translateY(calc(-130rem/16)) translateX(calc(-50vw + 70rem/16));
    }
    @media screen and (max-width: 767px) {
        transform: scaleX(1) translateY(calc(-120rem/16)) translateX(calc(-50vw + 50rem/16));
    }
}
.region-slider__item:before {
    transform: scaleX(0);
    content:'';
    position: absolute;
    width: calc(1rem/16);
    height: calc(60rem/16);
    transform-origin: top;
    background-color: var(--color-primary-light);
    bottom:0;
    transition: 0.1s ease;
    @media screen and (max-width: 767px) {
        height: calc(40rem/16);
    }
}
.swiper-slide-active.region-slider__item:before {
    transform: scaleX(1);
    transform-origin: top;
    transition: 0.4s 0.2s ease;
}
.swiper-slide-active.region-slider__item {
    opacity:1;
}
.swiper-slide-active .region-slider__title {
    opacity:0;
    transform: scale(0);
}
.region-slider__container {
    overflow: visible;
    padding: calc(100rem/16) 0;
    margin-top: calc(-100rem/16);
    margin-bottom: calc(-100rem/16);
}
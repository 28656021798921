.info-box {
    background: linear-gradient(180deg, #202020 0%, rgba(32,32,32,0.8) 100%);
    color:#fff;
}
.info-box > img {
    position: absolute !important;
    top:0;
    right:0;
    padding: calc(5rem/16);
    z-index: 9;
    background-color:var(--color-secondary);
}
.info-box__embed {
    padding-top: 100%;
    @media screen and (max-width: 767px) {
        padding-top: 50%;
    }
}
.info-box__sub-title {
    margin-bottom: calc(8rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(4rem/16);
    }
}
.info-box__title {
    margin-bottom: calc(8rem/16);
    text-transform: uppercase;
    font-family: var(--font-decorative);
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.info-box__content {
    padding: calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.info-box__sub-title {
    font-size: calc(14rem/16);
}
.info-box__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: calc(14rem/16);
    margin-bottom: calc(10rem/16);
}
.filter-dropdown__button {
    text-align:left;
    position: relative;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: 1;
    }
}
.filter-dropdown__button:after {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    color: currentColor;
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(12rem / 16);
    top: 50%;
    right: calc(15rem / 16);
    transform: translateY(-50%);
    z-index: 1;
    transition: transform 0.2s ease;

    @media screen and (min-width: 768px) {
        right: calc(26rem / 16);
    }
}
.filter-dropdown.show .filter-dropdown__button:after {
    transform: translateY(-50%) rotate(180deg);
}
.filter-dropdown__button__icon {
    height: calc(34rem/16);
    width: calc(34rem/16);
    color:#fff;
    border-radius: 50%;
    font-size: calc(18rem/16);
    position: absolute;
    left: 0;
    top: 0;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        margin: calc(6rem/16);
    }
}
.filter-dropdown__button__icon.icon-sightseeing {
    background-color: var(--color-red);
}
.filter-dropdown__button__icon.icon-gastronomy {
    background-color: var(--color-yellow);
}
.filter-dropdown__button__icon.icon-infrastructure {
    background-color: var(--color-green);
}
.filter-dropdown__button__icon.icon-traffic {
    background-color: var(--color-blue);
}
.filter-dropdown__button__icon.icon-wellness {
    background-color: #028337;
}
.filter-dropdown__button__icon.icon-bike {
    background-color: #a0b90b;
}
.filter-dropdown__button {
    padding-left: calc(50rem/16);
}
.filter-dropdown__menu {
    background-color: var(--color-secondary);
    padding: calc(20rem/16);
    color:#fff;
    border-radius: calc(7rem/16);
    width: 100%;
    margin-top: calc(10rem/16);
    border: 1px solid #fff;
    position: relative;
    margin-bottom: calc(20rem/16);
    overflow-y: auto;
    max-height: calc(400rem/16);
}
.filter-dropdown__menu--pos-absolute {
    position: absolute;
}
.filter-dropdown__checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.filter-dropdown__checkbox > label {
    width: 100%;
}
.filter-dropdown__checkbox__text {
    width: 100%;
    border-radius: calc(5rem/16);
    padding: calc(7rem/16) calc(13rem/16);
    border: 1px solid rgba(255, 255, 255, 0.52);
    display: block;
    position: relative;
}
.filter-dropdown__checkbox__box {
    position: absolute;
    right: calc(10rem/16);
    top: 50%;
    font-size: calc(14rem/16);
    transform: translateY(-50%);
    visibility: hidden;
}
.filter-dropdown__checkbox__input:checked ~ .filter-dropdown__checkbox__text > .filter-dropdown__checkbox__box{
    visibility: visible;
}
.filter-dropdown__checkbox__input:checked ~ .filter-dropdown__checkbox__text {
    border-color:#fff;
}
.filter-dropdown__menu-item.is-child{
    padding-left: calc(20rem/16);
}
.filter-dropdown__menu-item + .filter-dropdown__menu-item {
    margin-top: calc(8rem/16);
}
.filter-dropdown--small-padding .filter-dropdown__button {
    padding-left: calc(25rem/16);
}
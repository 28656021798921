.custom-cursor {
    position: fixed;
    pointer-events: none;
    z-index: 90000;
    mix-blend-mode: screen;
    top: 0;
    left: 0;
}
.custom-cursor:after {
    content: '';
    display: block;
    border-radius: 50%;
    background: var(--color-primary-light);
    transition: transform .2s ease-in-out, opacity .3s ease;
    position: absolute;
    top: calc(50rem / 16 / -2);
    left: calc(50rem / 16 / -2);
    pointer-events: none;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    transform-origin: center center;
    transform: scale(.2);
}
.custom-cursor.is-hover:after {
    opacity: .4;
    transform: scale(1);
    animation: squishing 1.5s 0.5s ease-in-out infinite;
}
.custom-cursor.is-active:after {
    opacity: .4;
}
.custom-cursor.is-hover.is-active:after {
    transform: scale(.8);
}

body.no-mix-blend-mode .custom-cursor {
    /*todo*/
    display: none
}

@media (hover: none) and (pointer:coarse) {
    .custom-cursor {
        display:none
    }
}

@keyframes squishing {
    10%, 40%, 80% { transform: scale(1, .9); }
    0%, 30%, 60%, 100% { transform: scale(.9, 1);}
}

.img-teaser__embed {
    padding-top: calc( 276 / 216 * 100%);
}
.img-teaser {
    border-radius: calc(5rem/16);
    overflow: hidden;
    position: relative;
}
.img-teaser__content {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    color:#fff;
    padding: calc(20rem/16);
    display: flex;
    z-index: 2;
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (max-width: 1599px) {
        padding: calc(10rem/16);
    }
}
.img-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-1rem/16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
.img-teaser__title {
    margin-bottom:0;
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
    }
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.main-navbar__overlay .img-teaser__title {
    font-size: calc(15rem/16);

    @media screen and (max-width: 1599px) and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.img-teaser__badge {
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(15rem/16);
    padding: calc(5rem/16) calc(8rem/16);
    line-height:1;
    display: inline-flex;
    margin-bottom: calc(10rem/16);
}
.img-teaser__badge--white {
    background-color: #fff;
    color: var(--color-primary);
}
.img-teaser--has-shadow {
    box-shadow: 0 20px 40px 0 rgba(32,32,32,0.6);
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(0rem/16);
        box-shadow: 0 10px 20px 0 rgba(32,32,32,0.5);
    }
}
.img-teaser-square {
    max-width: calc(185rem/16);
    @media screen and (max-width: 767px) {
        max-width: none;
    }
}
.img-teaser-square .img-teaser__content {
    padding: calc(10rem/16);
}
.img-teaser--search {
    width: auto;
}
.img-teaser__play-btn {
    width: calc(45rem/16);
    height: calc(45rem/16);
    background: #fff;
    color: var(--color-primary);
    padding: calc(10rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: calc(12rem/16);
    border-radius: 50%;
    border: none;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    position: absolute !important;
    top:50%;
    left:50%;
    z-index: 5 !important;
    transform: translateY(-50%) translateX(-50%);
}
.img-teaser--video .img-teaser__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.img-teaser--video .img-teaser__title {
    font-size: calc(18rem/16);
}
.img-teaser__video-length {
    font-size: calc(15rem/16);
    font-family: var(--font-default);
}
.img-teaser__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-top: calc(10rem/16);
}

.img-teaser--team .badge{
    white-space: normal;
    text-align: left;
}
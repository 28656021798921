.nav-overlay {
    opacity:0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    left:0;
    right:0;
    height: 100%;
    color:#fff;
    transform: translateX(-100%);
    transform-origin: left top;
    z-index: 99;
    transition: opacity 0.1s 0.2s, visibility 0.6s 0.0001s, ease-in-out transform 0.3s;
    @media screen and (max-width: 767px) {
        background-color: var(--color-secondary);
    }
}
.nav-overlay.show-overlay {
    opacity:1;
    visibility: visible;
    transform: translateX(0%);
    transition: opacity 0.1s, visibility 0.0001s, ease-in-out transform 0.3s;
}
@media screen and (min-width: 768px) {
    .nav-overlay:before {
        content: '';
        position: absolute;
        left: 0;
        z-index: -2;
        width: 46%;
        background: var(--color-secondary);
        display: block;
        height: 100%;
    }
}
@media screen and (min-width: 1200px){
    .nav-overlay:before {
        width: 43%;
    }
}
.card {
    box-shadow: 0 calc(10rem / 16) calc(30rem / 16) 0 rgba(0,0,0,0.5);
}
.card-header {
    position: relative;
    padding: calc(15rem / 16) calc(40rem / 16) calc(15rem / 16) calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(90rem / 16) calc(30rem / 16) calc(50rem / 16);
    }
}
.card-body {
    padding: calc(10rem / 16) calc(20rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(50rem / 16) calc(50rem / 16);
    }
}
.card-header__toggle {
    position: absolute;
    right: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);

    @media screen and (min-width: 768px) {
        right: calc(50rem / 16);
    }
}
.card-header.collapsed .card-header__toggle,
.card-header__toggle.collapsed {
    transform: translateY(-50%) rotate(90deg);
}
.card-header__title {
    font-size: calc(16rem / 16);
    font-family: var(--font-decorative);
    font-weight: var(--font-weight-decorative);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}

.accordion .card + .card {
    margin-top: calc(20rem / 16);
}
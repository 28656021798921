.hero-full-height {
    width: 100%;
    height: 100vh;
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(430rem / 16);
    }
}

.hero-full-height__bg {
    height: 100%;
    width: 100%;
    background-size: cover;
}

.hero-full-height__bg {
    background-image: var(--image-src-xs) !important;
    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
    }
}

.hero-full-height:after {
    content: '';
    position: absolute;
    height: calc(540rem / 16);
    width: 100%;
    display: block;
    background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(32, 32, 32, 0.7) 100%);
    z-index: 1;
    bottom: 0;
    @media screen and (max-width: 767px) {
        height: 30%;
    }
}

.hero-full-height__content {
    z-index: 2;
    position: absolute;
    bottom: calc(180rem / 16);
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    color: #fff;
    text-align: center;
    @media screen and (max-width: 767px) {
        bottom: calc(30rem / 16);
    }
}

.hero-full-height__content--less-margin-bottom {
    bottom: calc(100rem / 16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        bottom: calc(40rem / 16);
    }
    @media screen and (max-width: 767px) {
        bottom: calc(30rem / 16);
    }
}
.hero-full-height__title {
    font-size: calc(125rem/16);
    line-height: 1.15;
    text-transform: uppercase;
    padding-top: calc(30rem/16);
    letter-spacing: calc(7.5rem/16);
    font-family: var(--font-decorative);
    background:linear-gradient(0deg,rgba(255,255,255,0.0),rgba(255,255,255,0.3),rgba(255,255,255,0.9),rgba(255,255,255,1));
    background-clip: text;
    color:transparent;
    @media screen and (min-width: 768px) and (max-width: 1299px) {
        font-size: calc(80rem/16);
        letter-spacing: calc(4rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
        letter-spacing: calc(1rem/16);
        padding-top: calc(20rem/16);
        background:linear-gradient(0deg,rgba(255,255,255,0.3),rgba(255,255,255,0.9),rgba(255,255,255,1));
        background-clip: text;
    }
}
.hero-full-height__title--long {
    background-image:linear-gradient(0deg,rgba(255,255,255,0.3),rgba(255,255,255,0.5),rgba(255,255,255,0.8),rgba(255,255,255,1));

    @media screen and (max-width: 767px) {
        background-image:linear-gradient(0deg,rgba(255,255,255,0.3),rgba(255,255,255,0.9),rgba(255,255,255,1));
    }
}
@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, .hero-full-height__title { color: #fff; background-image: none; } /* IE11 */
}
.hero-full-height__custom-title {
    font-size: calc(90rem / 16);
    line-height: 1.15;
    text-transform: uppercase;
    margin-top: calc(30rem / 16);
    letter-spacing: calc(7.5rem / 16);
    font-family: var(--font-decorative);
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    @media screen and (max-width: 1599px) and (min-width: 1200px) {
        font-size: calc(70rem / 16);
        letter-spacing: calc(5rem / 16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(50rem / 16);
        letter-spacing: calc(5rem / 16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(22rem / 16);
        letter-spacing: calc(1rem / 16);
        margin-top: calc(20rem / 16);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
    }
}

.hero-full-height__brand {
    @media screen and (max-width: 1199px) {
        max-width: calc(200rem / 16);
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .hero-full-height__custom-title {
        color: #fff;
        background-image: none;
    }

    /* IE11 */
}

.hero-full-height__sub-title {
    letter-spacing: calc(10rem / 16);
    font-size: calc(20rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
    }
}

.scrolling-row {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(-8rem/16);
        margin-right: calc(-12rem/16);
    }
}
@media screen and (min-width: 768px) {
    .scrolling-row--centered {
        display: flex;
        justify-content: center;
    }
    .scrolling-row--invisible-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .scrolling-row--invisible-scrollbar::-webkit-scrollbar {
        display: none;
    }
}
.scrolling-row__item {
    display: inline-block;
    float:none;
    white-space: initial;
    @media screen and (max-width: 767px) {
        padding-left: calc(8rem/16);
        padding-right: calc(12rem/16);
    }
}
.scrolling-row--mobile {
    @media screen and (max-width: 767px) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        flex-wrap: nowrap;
        padding-bottom: calc(20rem/16);
        margin-left: calc(-8rem/16);
        margin-right: calc(-12rem/16);
    }
}
.scrolling-row--mobile__item {
    @media screen and (max-width: 767px) {
        display: inline-block;
        float:none;
        white-space: initial;
        padding-left: calc(8rem/16);
        padding-right: calc(12rem/16);
    }
}
.scrolling-row__button {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border:none;
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}
.scrolling-row__button.scrolling-row__button--disabled {
    opacity:0.5;
    pointer-events: none;
}
.link-list__item-link {
    background-color: var(--color-secondary);
    color:#fff;
    padding: calc(20rem/16) calc(50rem/16) calc(20rem/16) calc(30rem/16);
    display: flex;
    font-size: calc(18rem/16);
    position: relative;
    justify-content: center;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(12rem/16);
    }
}
.link-list--light .link-list__item-link {
    background-color: var(--color-white);
    color: var(--color-secondary);
}
.link-list__item-link:before {
    content:'';
    width: 100%;
    position: absolute;
    background-color: var(--color-primary-light);
    height: 100%;
    left:0;
    z-index: 1;
    top: 0;
    transform-origin: left;
    transform: scaleX(0.013);
    transition: transform 0.3s ease;
}
.link-list__item-link:hover:before {
    transform: scaleX(1);
}
.link-list__item-icon {
    position: absolute;
    right: calc(30rem/16);
    font-size: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        position: absolute;
        right: calc(5rem/16);
        font-size: calc(10rem/16);
        top:  calc(5rem/16);
        transform: translate(0);
    }
}
.link-list__item-link__text {
    z-index: 2;
    color:#fff;
}
.link-list--light .link-list__item-link__text {
    color: var(--color-secondary);
}
.link-list--light .link-list__item-link:hover .link-list__item-link__text {
    color: #fff;
}
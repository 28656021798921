.demi-infrastructure-overview-content-block .filter-categories .row {
    align-items: flex-end;
}

.filter-categories,
.filter-categories label,
.demi-event__filterbox,
.demi-event__filterbox label,
.demi-event__filterbox .custom-checkbox__text,
.demi-additionalservice__filterbox,
.demi-additionalservice__filterbox label,
.demi-additionalservice__filterbox .custom-checkbox__text {
    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}

.demi-additionalservice__filterbox,
.demi-event__filterbox {
    max-width: calc(800rem / 16);
    margin: 0 auto;
}

.demi-event__filterbox-btn,
.demi-additionalservice__filterbox-btn {
    margin-top: calc(10rem / 16);
}
.demi-event__filterbox-btn.btn-reset-filter {
    display: block;
    margin: calc(10rem/16) auto;
}

.show-more__item{
    display: inline-block;
    position: relative;
}


.show-more .show-more__item{
    overflow: hidden;
}

.show-more .show-more__item:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(80rem/16);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #f7f3ef 100%);
}

.show-more__btn{
    display: none;
    color: var(--color-primary-light);
    border: none;
    padding: 0;
    background-color: transparent;
    font-family: var(--font-default-bold);
}

.show-more .show-more__btn{
    display: block;
}

.show-more__btn .icon{
    margin-left: calc(5rem/16);
    font-size: calc(12rem/16);
    vertical-align: calc(-1rem/16);
}
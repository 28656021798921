.audio-item {
    background-color: #fff;
    color: var(--color-primary);
    padding: calc(10rem/16) calc(30rem/16);
    min-height: calc(80rem/16);
    @media screen and (max-width: 767px) {
        height: auto;
        padding: calc(10rem/16);
    }
}
.audio-item__title {
    font-size: calc(16rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        text-align:center;
    }
}

.audio-item__download {
    @media screen and (max-width: 767px) {
        letter-spacing: 0;
        width: 100%;
    }
}
.language-switch {
    font-size: calc(16rem/16);
    font-family: var(--font-decorative);
    margin-top: calc(100rem/16);
    @media screen and (max-width: 767px) {
        text-align:center;
    }

    @media screen and (min-width: 768px) and (max-height: 800px){
        margin-top: calc(40rem/16);
    }
}
.language-switch .dropdown-toggle:after {
    display: inline-block;
    margin-left: calc(10rem/16);
    content: var(--icon-arrow-right);
    font-family: iconfont;
    font-size: calc(10rem/16);
    border: none;
    transform: rotate(90deg);
}

.language-switch .dropdown-menu{
    top: -100%;
    transform: translateY(-100%);
}
.timeline-teaser {
    position: relative;
}
.timeline-teaser__embed {
    padding-top: calc( 258 / 459 * 100%);
}
.timeline-teaser__body {
    background-color: var(--color-secondary);
    color:#fff;
    padding: calc(45rem/16) calc(60rem/16) calc(50rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: calc(45rem/16) calc(40rem/16) calc(50rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(20rem/16);
    }
}
.timeline-teaser__badge {
    background-color: var(--color-primary);
    color:#fff;
    font-family: var(--font-default-bold);
    letter-spacing: calc(3rem/16);
    font-size: calc(18rem/16);
    line-height: calc(22/18);
    padding: calc(5rem/16) calc(8rem/16);
    display: inline-flex;
    margin-bottom: calc(15rem/16);
}
.timeline-teaser__sub-title {
    font-size: calc(14rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.timeline-teaser__title {
    text-transform: uppercase;
    font-size: calc(22rem/16);
    font-family: var(--font-decorative);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.timeline-teaser__btn{
    margin-top: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
.timeline-teaser:before {
    content: '';
    position: absolute;
    background: var(--color-primary-light);
    width: 61%;
    height: calc(1rem/16);
    top: calc(61rem/16);
    z-index: 2;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.timeline-teaser--right:before {
    left: -50%;
}
.timeline-teaser--left:before {
    right: -50%;
}
.timeline-teaser:after {
    content: '';
    position: absolute;
    background: var(--color-primary);
    width: calc(32rem/16);
    height: calc(32rem/16);
    top: calc(61rem/16);
    z-index: 2;
    border-radius: 50%;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.timeline-teaser--right:after {
    left: -50%;
    transform: translateX(-50%) translateY(-50%);
}
.timeline-teaser--left:after {
    right: -50%;
    transform: translateX(50%) translateY(-50%);
}
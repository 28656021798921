.icon-btn {
    position: relative;
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    border-radius: 50%;
    padding: calc(5rem / 16);
    letter-spacing: 0;

    @media screen and (min-width: 768px) {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
    }
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(10rem / 16);
}

/* sizes */
.icon-btn--lg {
    width: calc(55rem / 16);
    height: calc(55rem / 16);
}
.icon-btn--lg .icon-btn__icon {
    font-size: calc(27rem / 16);
}

/* icon button positions */
.icon-btn--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
}
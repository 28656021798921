/* Overview Layout */
.demi-infrastructure-overview {
    padding: 2rem 0;

    @media screen and (min-width: 768px) {
        padding: 3rem 0;
    }
}

.demi-infrastructure-overview-content-block + .demi-infrastructure-overview-content-block,
.demi-event-overview-content-block + .demi-event-overview-content-block,
.demi-additionalservice-overview-content-block + .demi-additionalservice-overview-content-block {
    margin-top: 1.5rem;

    @media screen and (min-width: 768px) {
        margin-top: 2rem;
    }
}

.demi-infrastructure-overview-content-block--result,
.demi-event-overview-content-block--result,
.demi-additionalservice-overview-content-block--result {
    margin-top: 1.5rem;

    @media screen and (min-width: 768px) {
        margin-top: 2rem;
    }
}

.demi-infrastructure-overview-content-block .container .container,
.demi-event-overview-content-block .container .container,
.demi-additionalservice-overview-content-block .container .container{
    /* Fixed nested container */
    padding: 0;
}
.demi-infrastructure-overview-content-block .container {
    max-width: calc(1640rem/16);
}

    /* Intro */
.demi-event-intro,
.demi-additionalservice-intro {
    margin-bottom: 1.5rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
    }
}
.demi-additionalservice-intro {
    text-align: center;
}

/* Teasers */
.demi-infrastructure-overview-teaser-list,
.demi-event-overview-teaser-list,
.demi-additionalservice-overview-teaser-list {
    margin-top: -2rem;
    clear: both;

    @media screen and (max-width: 767px) {
        margin-top: -.5rem;
        margin-left: -.25rem;
        margin-right: -.25rem;
    }
}

.demi-infrastructure-overview-teaser-list__item,
.demi-event-overview-teaser-list__item,
.demi-additionalservice-overview-teaser-list__item {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media screen and (max-width: 767px) {
        margin-top: .5rem;
        padding-left: .25rem;
        padding-right: .25rem;
    }
}

/* paging */
.demi-infrastructure-paging--top,
.demi-event-paging--top,
.demi-additionalservice-paging--top {
    margin-bottom: 1.5rem;
}

.demi-infrastructure-paging--bottom,
.demi-event-paging--bottom,
.demi-additionalservice-paging--bottom {
    margin-top: 1.5rem;
}
.screen-wrapper {
    height: 100vh;
    padding-top: calc(118rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(60rem/16);
    }
}
@media screen and (max-width: 767px) {
    .mega-nav {
        display: none;
    }
    .mega-nav__item.is-open > .mega-nav--level-1 {
        display: block;
    }
    .mega-nav {
        margin-bottom: .5rem;
    }
    .mega-nav__item {
        display: block;
        width: 100%;
    }
    .mega-nav__title-link,
    .mega-nav__item-content {
        display: block;
        width: 100%;
        padding: calc(17rem/16) 0;
        text-align:center;
    }
    .mega-nav__item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* level 0 */
    .mega-nav--level-0 {
        display: block;
        position: fixed;
        top: var(--main-navbar-height--xs);
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-secondary);
        color: #fff;
        margin: 0;
        padding: 1.5rem;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        transition: 120ms ease;
    }
    .mega-nav__item-content--level-0 {
        background-color: transparent;
        font-size: calc(20rem/16);
        text-transform: uppercase;
        letter-spacing: calc(5rem/16);
        color:#fff;
        font-family: var(--font-decorative);
        border:none;
        text-align:left;
    }
    .is-open > .mega-nav__item-content--level-0 {
        color: var(--color-primary-light);
    }
    .mega-nav__collapse-icon--level-0 {
        position: relative;
        display: inline-flex;
        align-items:center;
        z-index: 2;
        opacity:0.3;
        padding-left: calc(15rem/16);
    }
    .mega-nav__collapse-icon--level-0:before {
        content:'';
        width: calc(14rem/16);
        border-bottom:2px solid #fff;
        z-index: 2;
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0 {
        opacity:1;
    }
    .mega-nav__collapse-icon--level-0:after {
        content: var(--icon-arrow-right);
        position: absolute;
        display: inline-block;
        z-index: 2;
        font-family: iconfont;
        speak: none;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        transform: translate(0);
        left: calc(26rem/16);
        font-size: calc(10rem/16);
    }

    .mega-nav--level-1 {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: block;
        visibility: hidden;
        background-color: var(--color-secondary);
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        transform: translateX(100%);
        transition: transform 0.3s ease, visibility 0.3s 0.001s;
    }
    .is-open > .mega-nav--level-1 {
        visibility: visible;
        transition: transform 0.3s ease, visibility 0.3s;
        transform: translateX(0);
        z-index: 99;
    }
    .mega-nav__title-link--level-1 {
        color: var(--color-primary-light);
        letter-spacing: calc(6.5rem/16);
        font-size: calc(22rem/16);
        font-family: var(--font-decorative);
        text-transform: uppercase;
        padding-bottom:calc(10rem/16);
    }
    .mega-nav__item-content--level-1 {
        background-color: transparent;
        color:#fff;
        text-transform: uppercase;
        font-family: var(--font-decorative);
        letter-spacing: calc(3.5rem/16);
        font-size: calc(14rem/16);
        border: none;
        justify-content: center;
        padding-top: calc(10rem/16);
        padding-bottom: calc(10rem/16);
    }
    .mega-nav__item-content--level-1:focus, .mega-nav__item-content--level-1:active {
        outline: none;
    }
    .mega-nav__title-link--level-2, .mega-nav__item-content--level-2 {
        font-size: calc(16rem/16);
        color:var(--color-dark-grey);
        padding-top: calc(5rem/16);
        padding-bottom: calc(5rem/16);
        font-family: var(--font-default-bold);
        text-align: center;
        font-weight: var(--font-weight-bold);
        justify-content: center;
        background-color: transparent;
        border: none;
    }
    .mega-nav--level-2 {
        display: block;
    }
    .mega-nav--level-1__go-back {
        background-color: transparent;
        color: var(--color-dark-grey);
        border: none;
        font-size: calc(16rem/16);
        font-family:var(--font-default-bold);
        text-align: center;
        display: block;
        margin: 0 auto;
    }
    .mega-nav--level-1__go-back .icon {
        font-size: calc(9rem/16);
    }


}
.collapsed .mega-nav__collapse-icon {
    transform: rotate(0deg);
}
.mega-nav__collapse-icon {
    transform: rotate(180deg);
    transition: 0.2s ease;

    @media screen and (max-width: 767px) {
        display: block;
        font-size: calc(10rem/16);
        padding: calc(10rem/16);
    }
}
.consent-overlay{
    z-index: 3;
    display: none!important;
}

.consent-overlay.show{
    display: flex!important;
}

.video-list{
    position: relative;
}

.video-list__cookie-consent{
    position: absolute;
    top: calc(-8rem/16);
    bottom: calc(-62rem/16);
    left: calc(-50rem/16);
    right: calc(-50rem/16);
    z-index: 3;
    background-color: rgb(247 243 239 / 74%);
}
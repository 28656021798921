
.demi-additionalservice__filterbox-btn {
    display: block;
    margin: 0 auto;
}

.content-block + .pimcore_area_demi-additionalservice-list {
     margin-top: calc(70rem/16);
}
.accordion--demi-additionalservice {
    margin-top: calc(20rem/16);
}
.accordion--demi-additionalservice .card {
    box-shadow: 0 10px 20px 0 rgba(0,0,0, 0.3);
    margin-bottom: .9375rem !important;
}
.accordion--demi-additionalservice .card-header {
    margin-bottom: 0;
    line-height: 1.4;
    padding: 0;
    font-size: 1.125rem;
    text-transform: uppercase;
    background-color:#fff;
}
.accordion--demi-additionalservice .card-header a {
    padding: 1.875rem 1.875rem 1.4375rem;
}
.accordion--demi-additionalservice .card-body {
    background-color:#fff;
    color: var(--color-text-default);
    padding:10px 30px 30px;
}
.accordion--demi-additionalservice .card-header h5 {
    font-family: var(--font-decorative);
}
:root {
    --interactive-map-filter-width: calc(460rem/16);
}
.interactive-map-filter {
    position: absolute;
    left:0;
    background: linear-gradient(180deg, #202020 0%, rgba(32,32,32,0.8) 100%);
    width: var(--interactive-map-filter-width);
    color:#fff;
    z-index: 2;
    height: 100%;
    transition: 0.2s ease;
    transform: translateX(-100%);
    @media screen and (max-width: 767px) {
        width: calc(300rem/16);
    }
}
.interactive-map-filter.is-open {
    transform: translateX(0);
}
.interactive-map-filter__content {
    padding: calc(40rem/16);
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.interactive-map-filter.is-open .interactive-map__filter-button {
    transform: translateX(var(--interactive-map-filter-width)) rotate(0deg);
    @media screen and (max-width: 767px) {
        transform: translateX(calc(300rem/16)) rotate(0deg);
    }
}
.interactive-map__filter-button {
    position: absolute;
    top:0;
    transform: translateX(var(--interactive-map-filter-width)) rotate(180deg);
    background-color: var(--color-secondary);
    color:#fff;
    font-size: calc(16rem/16);
    z-index: 9;
    width: calc(27rem/16);
    height: calc(57rem/16);
    border: none;
    box-shadow: none;
    @media screen and (max-width: 767px) {
        transform: translateX(calc(300rem/16)) rotate(180deg);
    }
}
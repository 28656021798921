.hero__embed {
    padding-top: calc( 650 / 1920 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc( 431 / 375 * 100%);
    }
}
.hero__embed--small {
    padding-top: calc( 500 / 1920 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc( 431 / 375 * 100%);
    }
}
.hero {
    position: relative;
}
.hero__image {
    margin-bottom:0;
}
.hero__video{
    object-fit: cover;
}
.hero__content {
    z-index: 9;
    position: absolute;
    bottom: calc(70rem/16);
    width: 100%;
    height: auto;
    @media screen and (max-width: 767px) {
        bottom: calc(30rem/16);
    }
}
.hero__link-badge {
    width: calc(150rem/16);
    height: calc(150rem/16);
    background-image: url("/static/img/badge/badge.svg");
    background-repeat: no-repeat;
    background-size: cover;
    text-transform: uppercase;
    font-size: calc(14rem/16);
    color:#fff;
    font-family: var(--font-decorative);
    transform: rotate(10deg);
    position: absolute;
    z-index: 9;
    bottom: calc(100rem/16);
    right: calc(30rem/16);
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    transition: transform 0.2s ease;
    filter: drop-shadow(0 10px 20px rgba(3,35,12,0.4));
    line-height:1.2;
    padding: calc(25rem/16) calc(20rem/16) calc(20rem/16);
    @media screen and (max-width: 1599px) and (min-width: 768px) {
        bottom: calc(20rem/16);
        width: calc(110rem/16);
        height: calc(110rem/16);
        font-size: calc(10em/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(11rem/16);
        width: calc(120rem/16);
        height: calc(120rem/16);
        bottom: calc(20rem/16);
        right: calc(20rem/16);
        padding: calc(20rem/16) calc(15rem/16) calc(15rem/16);
    }
}
.hero__link-badge:hover {
    transform: rotate(0deg);
    color:#fff;
}
.hero__decorative-badge-wrapper {
    background-color: #fff;
    width: calc(150rem/16);
    height: calc(150rem/16);
    border-radius: 50%;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: calc(80rem/16);
        height: calc(80rem/16);
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.6);
    }
}
.hero__decorative-badge {
    max-width: calc(100rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(50rem/16);
    }
}
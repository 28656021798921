.icon-info-slide{
    padding: calc(40rem/16) 0;
    margin: calc(40rem/16) 0 calc(50rem/16);
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
}

.icon-info-item{
    text-align: center;
    color: var(--color-primary);
}

.icon-info-item__icon{
    font-size: calc(25rem/16);
    margin-bottom: calc(20rem/16);
    height: calc(45rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-info-item__icon.icon-water-quality,
.icon-info-item__icon.icon-map-pin{
    font-size: calc(42rem/16);
}

.icon-info-item__icon.icon-checkmark{
    font-size: calc(30rem/16);
}

.icon-info-item__main-info{
    text-transform: uppercase;
    font-family: var(--font-decorative);
    font-size: calc(13rem/16);
    line-height: calc(16/13);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-info-item__value{
    font-size: calc(30rem/16);
    line-height: calc(37/30);
}

.icon-info-item__value.is-small{
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    margin-top: calc(7rem/16);
    margin-bottom: calc(6rem/16);
}

.icon-info-item__text{
    text-transform: uppercase;
    font-size: calc(11rem/16);
    line-height: calc(14/11);
    letter-spacing: calc(2rem/16);
    margin-top: calc(5rem/16);
}

.icon-info-item__text+.icon-info-item__text{
    margin-top: calc(10rem/16);
}

.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateY(-100%);
    transition: transform 450ms ease-in-out, visibility 0.001ms 450ms, opacity 450ms ease;
    background: white;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    z-index: -1;
    @media screen and (max-width:767px) {
        padding-left:0;
        transition: transform 250ms ease-in-out, visibility 0.001ms 250ms, opacity 250ms ease;
    }
}
.overlay__container {
    position:relative;
    height:100%;
    padding-bottom:calc(50rem/16);
    @media screen and (max-width:767px) {
        padding-left: calc(40rem/16);
        padding-right: calc(40rem/16);
        position:relative;

    }
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    //opacity: 1;
    z-index: 4;
    transition: transform 400ms ease-in-out, visibility 0.001ms, opacity 1ms ease;
    @media screen and (max-width: 767px) {
        z-index: 4;
        transition: transform 250ms ease-in-out, visibility 0.001ms, opacity 1ms ease;

    }
}
.overlay__close {
    position:relative;
    float:right;
    right:20px;
    top:20px;
    background-color:#fff;
    width:calc(70rem/16);
    height:calc(70rem/16);
    z-index: 10;
    box-shadow:none;
    border:none;
    color:#000;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:calc(20rem/16);
    cursor:pointer;
    transition: all 0.2s ease;
    @media screen and (max-width:767px) {
        width:calc(50rem/16);
        height:calc(50rem/16);
        right:2px;
        top:2px;
    }
}
.overlay__close:hover {
    background-color:var(--color-primary-dark);
    color:#fff;
}
.overlay__content {
    flex: auto;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
:root {
    --container-max-width: calc(1432rem/16);
    --container-md-max-width: calc(1220rem/16);
    --container-sm-max-width: calc(974rem/16);
    --container-narrow-max-width: calc(794rem/16);
    --container-wide-max-width: calc(1675rem/16);
}

.container.container {
    max-width: var(--container-max-width);
    width: 100%;
}
.container.container-sm {
    max-width: var(--container-sm-max-width);
    width: 100%;
}
.container.container-md {
    max-width: var(--container-md-max-width);
    width: 100%;
}
.container.container-narrow {
    max-width: var(--container-narrow-max-width);
    width: 100%;
}
.container--no-padding-xs {
    @media screen and (max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
}
@media screen and (min-width: 768px) {
    .container__break-right-half {
        width: calc(50vw - calc(var(--gutter) / 2));
    }
}
@media screen and (min-width: 768px) {
    .container__break-left-half {
        width: calc(50vw - calc(var(--gutter) / 2));
        margin-left: 100%;
        transform: translateX(-100%);
    }

    .container-md__break-left-half {
        width: calc(((100vw - var(--container-md-max-width)) / 2) + var(--container-md-max-width));
        margin-left: 100%;
        transform: translateX(-100%);
    }
}

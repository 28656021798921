.young-styria-box {
    border: 1px solid #d8d8d8;
    position: relative;
    text-align: center;
    background-color: #fff;
    padding: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(20rem / 16);
    }
}
.young-styria-box__star {
    padding-bottom: calc(10rem/16);
    width: calc(20rem/16);

    @media screen and (min-width: 768px) {
        position: absolute;
        top:0;
        left: 50%;
        width: calc(40rem/16);
        padding-bottom:0;
        transform: translateY(-50%) translateX(-50%);
    }
}
.young-styria__logo {
    max-width: calc(200rem/16);
    width: 100%;
    margin-bottom: calc(20rem/16);
    margin-top: calc(10rem/16);
}
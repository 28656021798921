.feature-list__item {
    padding-top: calc(20rem/16);
    padding-bottom: calc(20rem/16);
    font-size: calc(16rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        margin-left: calc(10rem/16);
    }
}
.feature-list__item + .feature-list__item {
    border-top: 1px solid var(--color-grey);
}
.feature-list__item:last-of-type {
    border-bottom: 1px solid var(--color-grey);
}
.feature-list__item-title {
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(27/18);
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-bottom: calc(5rem/16);
    }
}
.feature-list__item-icon {
    font-size: calc(25rem/16);
    width: calc(30rem/16);
    margin-right: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        width: calc(20rem/16);
    }
}
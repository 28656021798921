/* width */
.custom-scrollbar::-webkit-scrollbar,
.mega-nav__list--level-0::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track,
.mega-nav__list--level-0::-webkit-scrollbar-track {
  background: var(--color-grey);
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb,
.mega-nav__list--level-0::-webkit-scrollbar-thumb {
  background: var(--color-primary-light);
  border-radius: 1000px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.mega-nav__list--level-0::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}
.list-element-with-prefix {
    font-size: calc(16rem/16);
    display: flex;
    margin: calc(7rem/16) 0;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.list-element-with-prefix__pre {
    font-family: var(--font-default-bold);
    margin-left: calc(23rem/16);
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
        margin-left: calc(16rem/16);
    }
}
.list-element-with-prefix__post {
    margin-left: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(16rem/16);
    }
}
.list-element-with-prefix__post a{
    color: var(--color-primary-light);
    text-decoration: underline;
}
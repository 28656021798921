.file-input {
    margin-top: calc(80rem/16);
    margin-bottom: calc(60rem/16);
}
.file-input__btn {
    position: absolute;
    top: calc(-65rem/16);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}
.file-input__form-control {
    border-radius: calc(30rem/16) !important;
    display: none;
}
.file-input__nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: calc(50rem/16);
    width: calc(410rem/16);
    border: 1px solid white;
    border-radius: calc(30rem/16);
    margin: 0 auto;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    @media screen and (max-width: 767px) {
        height: calc(40rem/16);
        width: calc(300rem/16);
        font-size: calc(14rem/16);
    }
}
.file-input__nav-tab {
    width: 50%;
    height: 100%;
    border-radius: calc(30rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-input__nav-tab.active {
    background-color: white;
    color: var(--color-secondary);
}
.file-input__tab-pane {
    /*min-height: calc(300rem/16);*/
}
.info-overlay{
    position: fixed;
    bottom: calc(12rem/16);
    right: calc(12rem/16);
    z-index: 100;
    @media screen and (min-width: 768px){
        bottom: calc(20rem/16);
        right: calc(20rem/16);
    }
}
.info-overlay__toggle{
    background: var(--color-primary-light);
    width: calc(50rem/16);
    height: calc(50rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    outline: none;
    border-radius: 50%;
    color: #FFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    transition: background-color 200ms ease-in-out;
    @media screen and (min-width: 768px){
        width: calc(70rem/16);
        height: calc(70rem/16);
    }
}
.info-overlay__toggle:hover{
    background: var(--color-primary);
    color: #FFF;
}
.info-overlay__toggle.info-overlay__toggle--white{
    background: #FFF;
    color: var(--color-primary-light);
}
.info-overlay__toggle.info-overlay__toggle--white:hover{
    background: var(--color-light-grey);
    color: var(--color-primary-light);
}
.info-overlay__icon{
    font-size: calc(28rem/16);
    transition: transform 200ms ease-in-out;
}
.info-overlay__toggle.is-open .info-overlay__icon{
    font-size: calc(14rem/16);
    transform: rotate(180deg);
}
.info-overlay__toggle.is-open .info-overlay__icon:before{
    content: var(--icon-close-thin);
}

.info-overlay__content{
    position: absolute;
    bottom: calc(22rem/16);
    right: 0;
    margin-left: calc(12rem/16);
    background-color: var(--color-white-dark);
    width: calc(100vw - calc(24rem/16));
    padding: calc(20rem/16) calc(20rem/16) calc(40rem/16) calc(20rem/16);
    color: #FFF;
    z-index: -1;
    box-shadow: 0 2px 40px 0 rgba(0,0,0,0.5);
    transform: scale(0);
    transition: transform 200ms ease-in-out;
    transform-origin: bottom right;
    @media screen and (min-width: 768px){
        bottom: calc(50rem/16);
        right: calc(25rem/16);
        margin: 0;
        width: calc(460rem/16);
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 1200px){
        padding: calc(40rem/16);
        font-size: calc(15rem/16);
    }
}
.info-overlay__content.is-open{
    transform: scale(1);
}
.info-overlay__map{
    position: absolute;
    bottom: 0;
    right: 60%;
    width: calc(150rem/16);
    z-index: -1;
    @media screen and (min-width: 768px){
        width: calc(200rem/16);
    }
}
.info-overlay__map-text{
    z-index: 1;
    color: #FFF;
    font-family: var(--font-decorative);
    margin: calc(22rem/16);
    -webkit-line-clamp: 3;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: calc(10rem/16);
    text-align: center;
    @media screen and (min-width: 768px){
        margin: calc(30rem/16);
        font-size: calc(12rem/16);
    }
}
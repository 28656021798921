.experience-teaser__embed {
    padding-top: calc( 602 / 457 * 100%);
}
.experience-teaser {
    overflow: hidden;
    position: relative;
}
.experience-teaser__content {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    color:#fff;
    padding: calc(26rem/16);
    display: flex;
    z-index: 2;
    align-items: flex-end;
}
.experience-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-2rem/16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
.experience-teaser__title {
    font-size: calc(22rem/16);
    margin-bottom: calc(22rem/16);
    font-family: var(--font-default-bold);
}
.experience-teaser {
    transition: transform 0.3s  cubic-bezier(0.7, 0, 0.4, 1);
}
.experience-teaser__counter {
    position: absolute;
    opacity: 0;
    z-index: 9;
    color:#fff;
    font-family: var(--font-decorative);
    transform: scale(0);
    transition: transform 0.7s ease, opacity 0.3s ease;
    font-size: calc(140rem/16);
    text-shadow: 0 2px 30px rgba(0,0,0,0.2);
    pointer-events: none;
    @media screen and (max-width: 767px) {
        font-size: calc(70rem/16);
    }
}

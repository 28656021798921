.experience-slider-area__claim {
    position: absolute;
    top:0;
    left:0;
    right:0;
    margin: 0 auto;
    z-index: 1;
    height: calc(200rem/16);
    @media screen and (max-width: 767px) {
        height: auto;
        max-width: 90%;
        margin-top: calc(20rem/16);
    }
}
.experience-slider-area {
    position: relative;
    padding-top: calc(60rem/16);
    min-height: calc(400rem/16);
    background-size: cover;
    background-repeat: no-repeat;
}
.experience-slider-area__content {
    z-index: 2;
    position: relative;
    padding-bottom: calc(40rem/16);
}
.experience-slider-area:before {
    z-index: 0;
    background: linear-gradient(0deg, rgba(238,238,238,0.5) 0%, var(--body-bg-color) 100%);
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
}

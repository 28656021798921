/* Event & Infrastructure Detail */

.demi-infrastructure-detail,
.demi-event-detail {
    padding-bottom: 2rem;

    @media screen and (min-width: 768px) {
        padding-bottom: 3rem;
    }
}
.demi-infrastructure-detail-list {
    padding-inline-start: 0 !important;
}
.demi-infrastructure-detail .breadcrumb,
.demi-event-detail .breadcrumb {
    padding: .75rem 2rem;
    margin: 0 auto;
}
/* Content blocks */
.demi-infrastructure-detail-content-block,
.demi-infrastructure-further-content,
.demi-event-detail-content-block {
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
        margin-top: 5rem;
    }
}
.demi-event-detail-content-block {
    overflow: hidden;
}

.demi-infrastructure-detail-content-block--health {
    margin-top: 4rem;

    @media screen and (min-width: 768px) {
        margin-top: 8rem;
    }
}

.demi-infrastructure-detail-content-block--health + .demi-infrastructure-detail-content-block,
.demi-infrastructure-detail-content-block--health + .demi-event-detail-content-block{
    margin-top: 4rem;

    @media screen and (min-width: 768px) {
        margin-top: 8rem;
    }
}

.demi-infrastructure-detail-content-block h1,
.demi-event-detail-content-block h1 {
    margin-bottom: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}

.demi-infrastructure-detail-content-block h2,
.demi-event-detail-content-block h2 {
    text-transform: none;
}

.demi-infrastructure-detail-content-block--contact .row {
    @media screen and (max-width: 767px) {
        margin-top: calc(-20rem / 16);
    }
}

.demi-infrastructure-detail-content-block--contact .row > [class*=" col"],
.demi-infrastructure-detail-content-block--contact .row > [class^=col] {
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem / 16);
    }
}


.demi-event-detail-content-block--contact > .container > .wysiwyg{
    background-color:#fff;
    padding: calc(30rem/16);
}

.demi-infrastructure-back-button, .demi-event-back-button {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
}

/* Accordion Collapse */
.demi-infrastructure-detail-content-block--gallery {
    overflow-x: hidden;
}
.demi-infrastructure-detail-content-block--gallery figure,
.demi-event-detail-content-block--gallery figure {
    margin: 0;
}

.demi-infrastructure-detail-content-block--times .card-header,
.demi-infrastructure-detail-content-block--prices .card-header,
.demi-event-detail-content-block--times .card-header {
    padding: 0;
}

.demi-infrastructure-detail-content-block--times .card-header a,
.demi-infrastructure-detail-content-block--prices .card-header a,
.demi-event-detail-content-block--times .card-header a {
    display: block;
    padding: 1rem calc(30rem / 16) calc(10rem / 16) calc(15rem / 16);
    font-family: var(--font-decorative);

    position: relative;

    @media screen and (min-width: 768px) {
        padding: 1.25rem 3rem 1rem 1.25rem;
    }
}

.demi-infrastructure-detail-content-block--times .card-header a.collapsed,
.demi-infrastructure-detail-content-block--prices .card-header a.collapsed,
.demi-event-detail-content-block--times .card-header a.collapsed {
    background-color: var(--color-secondary);
    color: #fff;
    font-family: var(--font-decorative);
}

.demi-infrastructure-detail-content-block--times .card-header a .icon,
.demi-infrastructure-detail-content-block--prices .card-header a .icon,
.demi-event-detail-content-block--times .card-header a .icon {
    font-size: 1rem;
    position: absolute;
    right: calc(15rem / 16);
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 120ms ease-out;

    @media screen and (min-width: 768px) {
        right: calc(20rem / 16);
        top: 50%;
        font-size: 1.25rem;
    }
}

.demi-infrastructure-detail-content-block--times .card-header a.collapsed .icon,
.demi-infrastructure-detail-content-block--prices .card-header a.collapsed .icon,
.demi-event-detail-content-block--times .card-header a.collapsed .icon {
    transform: translateY(-50%) rotate(0deg);
}

/* Slider */

.demi-infrastructure-detail-content-block--gallery .slick-dots,
.demi-event-detail-content-block--gallery .slick-dots {
    display: none !important;
}

.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-next,
.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: calc(15rem / 16) calc(13rem / 16);
    font-size: 20px;
}

.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-prev,
.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-prev {
    left: 0;
}

.demi-infrastructure-detail-content-block--gallery .btn.slick-arrow.slick-next,
.demi-event-detail-content-block--gallery .btn.slick-arrow.slick-next {
    right: 0;
}

.demi-infrastructure-detail-content-block--times .container,
.demi-infrastructure-detail-content-block--contact .container,
.demi-infrastructure-detail-content-block--links .container,
.demi-infrastructure-detail-content-block--back .container,
.demi-infrastructure-detail-content-block--intro .container,
.demi-event-detail-content-block--intro .container,
.demi-event-detail-content-block--times .container,
.demi-event-detail-content-block--contact .container,
.demi-event-detail-content-block--links .container,
.demi-event-detail-content-block--back .container,
.demi-infrastructure-detail-content-block--prices .container,
.demi-event-detail-content-block--date .container
{
    max-width: var(--container-sm-max-width);
    width: 100%;
}

#times-accordion .card-body,
#price-acc-detail-1 .card-body {
    padding: 20px;
    border-top: 1px solid #fff;
}
.demi-event-back-button {
    margin: 0 auto;
    display: block;
    min-width: calc(250rem/16);
}
.accordion--demi-infrastructure h5 {
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.accordion--demi-infrastructure h5 a {
    display: flex;
    justify-content: space-between;
}
.infrastructure__hl {
    font-family: var(--font-decorative);
    text-transform: uppercase;
    margin-bottom: calc(10rem/16);
}

.accordion--demi-small .card-header a {
    padding: 12px 20px 10px;
    font-family: var(--font-decorative);
    font-size: 15px;
    text-transform: none;
    margin: 0;
}

.accordion--demi-small .accordion-item__header-link .icon{
    font-size: calc(14rem/16);
}

.accordion--demi-small.accordion--demi-additionalservice .card-header a {
    padding: calc(12rem/16) calc(20rem/16) calc(10rem/16);
}

.accordion--demi-small.accordion--demi-additionalservice .card-body {
    padding: calc(10rem/16) calc(20rem/16) calc(10rem/16);
}

.accordion--demi-additionalservice.accordion--demi-small .card{
    box-shadow: none;
    margin-bottom: 0!important;
}
.big-img-text-slide {
    position: relative;
    padding-top: calc(50rem/16);
    padding-bottom: calc(50rem/16);
}
.big-img-text-slide__wysiwyg a, .big-img-text-slide__wysiwyg a:hover {
    color:#fff;
    text-decoration: underline;
}
.big-img-text-slide__bg {
    height: calc(615rem/16);
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: calc(300rem/16);
    }
}
.big-img-text-slide__content {
    position: absolute;
    width: calc(460rem / 16);
    height: 100%;
    background-color: var(--color-secondary);
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    top: 0;
    bottom: 0;
    padding: calc(60rem / 16);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: self-start;
    @media screen and (max-width: 767px) {
        width: 100%;
        max-width: calc(260rem/16);
        left: 0;
        right:0;
        margin: -70% auto 0;
        padding: calc(15rem/16);
        text-align: center;
        position: relative;

    }
}
.big-img-text-slide__title {
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(15rem/16);
    }
}
.big-img-text-slide__btn {
    margin-top: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin: calc(20rem/16) auto 0;
    }
}
.anchor-nav {
    background-color: var(--color-secondary);
    color:#fff;
    z-index: 101;
    display: block;
    @media screen and (max-width: 767px) {
        padding-left: 15%;
        padding-right:15%;
        z-index: 999;
    }

    @media screen and (min-width: 768px){
       display: flex;
        align-items: center;
        justify-content: center;
    }
}

.no-hero .anchor-nav:not(.anchor-nav--lp){
    @media screen and (max-width: 767px){
       top: calc(145rem/16)!important; /* important necessary for overwritting inline styling */
    }
}

.no-hero.is-no-subregion .anchor-nav:not(.anchor-nav--lp){
    @media screen and (min-width: 768px){
       top: calc(118rem/16)!important;
    }
    top: calc(120rem/16)!important;
}


.no-hero.nav-not-affix .anchor-nav {
    @media screen and (max-width: 767px){
        top: calc(60rem/16)!important; /* important necessary for overwritting inline styling */
    }
}

.nav-not-affix .anchor-nav{
    @media screen and (min-width: 768px){
       top: calc(118rem/16)!important; /* important necessary for overwritting inline styling */
    }
}

.nav-affix .anchor-nav.is-affix {
    top: calc(145rem/16)!important; /* important necessary for overwritting inline styling */
    @media screen and (min-width: 768px){
        top: calc(149rem/16)!important; /* important necessary for overwritting inline styling */
    }
}

.is-no-subregion.nav-affix .anchor-nav.is-affix {
    top: calc(120rem/16)!important; /* important necessary for overwritting inline styling */
    @media screen and (min-width: 768px){
        top: calc(118rem/16)!important; /* important necessary for overwritting inline styling */
    }
}


.anchor-nav__link {
    font-size: calc(14rem/16);
    padding: calc(14rem/16) calc(20rem/16);
    display: block;
    transition: background-color 0.2s ease;
}
.anchor-nav__link:hover {
    color: #fff;
    background-color: var(--color-primary)
}
.anchor-nav__link.active {
    background-color: var(--color-primary);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.5);
}
.anchor-nav__item {
    white-space: nowrap;
    @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
}
.anchor-nav__list{
    display: flex;
    padding-bottom:0;
    overflow-x: auto;
}

.anchor-nav--lp .anchor-nav__list {
    @media screen and (min-width: 768px) {
        justify-content: center;
    }
}
.anchor-nav--lp .anchor-nav__link {
    text-transform: uppercase;
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(2.4rem/16);
    position: relative;
    padding: calc(20rem/16) calc(30rem/16);
}
.anchor-nav--lp .anchor-nav__link.active {
    background-color: transparent;
}
.anchor-nav--lp .anchor-nav__link.active:before {
    position: absolute;
    width: 100%;
    height: calc(6rem/16);
    background-color: #fff;
    content:'';
    bottom:0;
    left:0;
    right:0;
}
.anchor-nav__prev, .anchor-nav__next, .anchor-nav__prev:active, .anchor-nav__next:active,
.anchor-nav__prev:hover, .anchor-nav__next:hover{
    position: absolute;
    width: 15%;
    background-color:#fff;
    box-shadow: none;
    z-index: 9;
    color: var(--color-secondary);
    display: flex;
    height: calc(59rem/16);
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-secondary);
    @media screen and (min-width: 768px) {
        display: none;
    }
    @media screen and (max-width: 767px){
       height: 49px;
    }
}

.anchor-nav__next {
    right:0;
}
.anchor-nav__prev {
    left:0;
}
.anchor-nav__prev.disabled, .anchor-nav__next.disabled {
    opacity:0.7;
}
.anchor-nav__placeholder {
    min-height: calc(49rem/16) !important;
}
:root {
    --title-line-height: 1.3125em;
    --title-max-lines: 3;
}
.tour-teaser {
    position: relative;
    overflow: hidden;
    border-radius: calc(5rem/16);
    box-shadow: 0 9px 15px 0 rgba(32,32,32,0.6);
    @media screen and (max-width: 767px) {
        box-shadow: 0 10px 20px 0 rgba(32,32,32,0.5);
    }
}
.tour-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-1rem/16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
.tour-teaser__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    color: #fff;
    padding: calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16);
    }
}

.tour-teaser__content__title {
    font-size: calc(11rem / 16);
    line-height: var(--title-line-height);
    margin-top: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        margin-top: calc(12rem / 16);
    }

    @media screen and (max-width: 767px) {
        line-height: var(--title-line-height);
        max-height: calc(var(--title-line-height) * var(--title-max-lines));
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: var(--title-max-lines);
        -webkit-box-orient: vertical;
    }
}
.tour-teaser__info-wrapper {
    gap: calc(10rem/16);
    position: absolute;
    z-index: 2;
    width: calc(100% - 2 * 20rem/16);
    top: calc(20rem/16);
    left: calc(20rem/16);

    @media screen and (max-width: 767px) {
        top: calc(10rem / 16);
        left: calc(10rem / 16);
        width: calc(100% - 2 * 10rem/16);
    }
}
.tour-teaser__status-wrapper {
    gap: calc(10rem/16);
}

.tour-teaser__arrival,
.tour-teaser__status {
    /*top: calc(20rem/16);*/
    /*left: calc(20rem/16);*/
    z-index: 2;
    display: flex;
    /*@media screen and (max-width: 767px) {*/
    /*    top: calc(10rem / 16);*/
    /*    left: calc(10rem / 16);*/
    /*}*/
}
.tour-teaser__arrival-icon,
.tour-teaser__status-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #fff;
    border-radius: .25rem;
    font-size: calc(18rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);

    @media screen and (max-width: 767px) {
        width: 1.5rem;
        height: 1.5rem;
        font-size: calc(12rem/16);
    }
}
.tour-teaser__arrival-text,
.tour-teaser__status-text {
    font-size: calc(10rem/16);
    color:#fff;
    padding-left: calc(5rem/16);
    display: inline-flex;
    align-items: center;
    text-shadow:0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.3);
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.footer-socials {
    background-color: var(--color-primary);
    padding-top: calc(42rem/16);
    padding-bottom: calc(40rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(80rem/16);
    }
}
.footer--has-smaller-padding .footer-socials {
    padding-top: calc(40rem/16);
}
.footer-socials__list {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-socials__item {
    width: calc(46rem/16);
    height: calc(46rem/16);
    border-radius: 50%;
    background-color:#fff;
    color: var(--color-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: calc(40rem/16);
    line-height:1;
    border: 2px solid #fff;
    margin-left: calc(12rem/16);
    margin-right: calc(12rem/16);
    transition: background-color 0.2s ease, color 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
        width: calc(38rem/16);
        height: calc(38rem/16);
        font-size: calc(28rem/16);
    }
}
.footer-socials__item:hover {
    background-color: var(--color-primary);
    color:#fff;
}

.footer-socials__item .icon-tiktok{
    font-size: calc(19rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}
.footer-socials__item .icon-linkedin{
    font-size: calc(19rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}
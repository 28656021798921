.flatrate-teaser {
    box-shadow: 0 10px 20px 0 rgba(32,32,32,0.2);
    margin-bottom: calc(20rem/16);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    border-radius: calc(5rem/16);
    overflow: hidden;
    z-index: 1;
}
.flatrate-teaser__embed {
    padding-top: calc( 280 / 230 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc( 200 / 230 * 100%);
    }
}
.flatrate-teaser__embed--small {
    padding-top: 95%;
}
.flatrate-teaser__content {
    background-color: var(--color-primary);
    padding: calc(20rem/16);
    color:#fff;
    display: flex;
    flex: 1;
    align-items: flex-end;
}
.flatrate-teaser__content-inner {
    margin-top: calc(-150rem/16);
    z-index: 2;
    width: 100%;
}
.flatrate-teaser__title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    margin-bottom: calc(15rem/16);
}
.flatrate-teaser__location, .flatrate-teaser__date {
    margin-bottom: calc(10rem/16);
}
.flatrate-teaser__badge {
    background-color: var(--color-primary);
    margin-bottom: calc(15rem/16);
    color: #fff;
    padding: calc(3rem/16) calc(8rem/16);
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    display: inline-block;
}
.flatrate-teaser__badge--white {
    background-color:#fff;
    margin-bottom: calc(15rem/16);
    color: var(--color-primary);
    padding: calc(3rem/16) calc(8rem/16);
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    display: inline-block;
}
.flatrate-teaser__wysiwyg {
    margin-bottom: calc(15rem/16);
    line-height: calc(22/16);
}
.flatrate-teaser__img-wrapper:before {
    background: linear-gradient(180deg,rgba(0,54,16,0),#003610);
    content:'';
    position: absolute;
    bottom: calc(-2rem/16);
    z-index: 1;
    width: 100%;
    height: calc(200rem/16);
}
.flatrate-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-1rem / 16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
/* important needed for strech-link */
.flatrate-teaser__wishlist-btn {
    position: absolute !important;
    z-index: 9 !important;
    right:calc(10rem/16);
    top: calc(10rem/16);
}
.flatrate-teaser__pool-enquiry {
    position: absolute !important;
    z-index: 9 !important;
    height: calc(35rem/16);
    border-radius: calc(20rem/16);
    background-color: #fff;
    color: var(--color-primary);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    font-size: calc(11rem/16);
    border:none;
    box-shadow: none;
    left: calc(10rem/16);
    font-family:var(--font-default-bold);
    width: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    top: calc(10rem/16);
    padding: calc(4rem/16) calc(15rem/16) calc(4rem/16) calc(4rem/16);
}
.flatrate-teaser__pool-enquiry__icon {
    background-color: var(--color-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    font-size: calc(12rem/16);
    margin-right: calc(10rem/16);
    width: calc(27rem/16);
    height: calc(27rem/16);
    border-radius: 50%;
    text-align: center;
}
.flatrate-teaser__pool-enquiry.active .flatrate-teaser__pool-enquiry__icon:before {
    content: var(--icon-check);
}
.flatrate-teaser__topic-badge {
    background-color:var(--color-light-grey);
    position: absolute !important;
    top:calc(14rem/16);
    right: calc(14rem/16);
    width: calc(70rem/16);
    height: calc(70rem/16);
    font-size: calc(11rem/16);
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    z-index: 6;
    flex-direction: column;
    text-align: center;
}
.flatrate-teaser__topic-badge-count {
    font-size: calc(28rem/16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    line-height: calc(30/28);
}
.flatrate-teaser__accessibility-item {
    width: calc(40rem/16);
    height: calc(40rem/16);
    background-color:#fff;
    position: relative;
    border-radius: calc(4rem/16);
}
.flatrate-teaser__accessibility-list {
    margin-bottom: calc(10rem/16);
}
.flatrate-teaser__accessibility-item--checked:before {
    content: var(--icon-check);
    position: absolute;
    bottom: calc(-5rem/16);
    right:calc(-5rem/16);
    background-color: var(--color-success);
    border-radius: 50%;
    width: calc(15rem/16);
    height: calc(15rem/16);
    font-family: iconfont;
    speak: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(7rem/16);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
}
.flatrate-teaser__young-styria {
    position: absolute;
    top:calc(10rem/16);
    width: calc(140rem/16);
    right: calc(10rem/16);
    z-index: 1;
}
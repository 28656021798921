.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.embed-responsive-item.embed-responsive-item--center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.accessibility-item {
    background-color: #fff;
    border-radius: calc(5rem/16);
    width: calc(25rem/16);
    height: calc(25rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    margin-right: calc(5rem/16);
}
.accessibility-item-big {
    width: calc(50rem/16);
    height: calc(50rem/16);
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: calc(5rem/16);
    position: relative;
}
.accessibility-item__check {
    position: absolute;
    bottom: calc(-2rem/16);
    right: calc(-2rem/16);
    height: calc(15rem/16);
    width: calc(15rem/16);
    background-color: var(--color-success);
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(8rem/16);
    border-radius: 50%;
}
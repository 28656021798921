@media screen and (min-width: 768px) {
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        color: var(--color-dark-grey);
        font-size: calc(16rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-weight-bold);
        text-transform: none;
        margin-bottom: calc(16rem / 16);
        letter-spacing: 0;
        display: block;
        transition: color 0.2s ease;
    }
}

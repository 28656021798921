.demi-infrastructure-sidebar {
    @media screen and (max-width: 767px) {
        margin-bottom: 2rem;
    }
}

.demi-infrastructure-sidebar__hl {
    font-size: 1.5rem;
    margin-bottom: .5rem
}

.demi-infrastructure-sidebar__collapse-icon {
    font-size: .75rem;
}

.demi-infrastructure-sidebar__head {
    padding: .5rem 0;
    cursor: pointer;
}

.demi-infrastructure-sidebar__filters .demi-infrastructure-sidebar__box:not(:first-child) {
    border-top: calc(1rem / 16) solid #ddddd5;
}

.demi-infrastructure-sidebar__filters .form-group--search {
    margin-top: 1.25rem;
}

.demi-infrastructure-sidebar__head .demi-infrastructure-sidebar__collapse-icon {
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
    font-size: .5rem;
}

.demi-infrastructure-sidebar__head.collapsed .demi-infrastructure-sidebar__collapse-icon {
    transform: rotate(0deg);
}

.demi-infrastructure-sidebar__body .custom-checkbox + .custom-checkbox{
    margin-top: calc(5rem/16);
}

.demi-infrastructure-sidebar__title{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    padding: calc(5rem/16) 0;
}

.filter-sidebar__datepicker .datepicker:after{
    color: #fff;
}

.demi-infrastructure-sidebar__head .demi-infrastructure-sidebar__collapse-icon{
    position: relative;
    top: calc(4rem/16);
}

.period-dates__collapse{
    display: none;
}

.period-dates__collapse.show{
    display: block;
}
.interactive-map {
    position: relative;
    overflow: hidden;
}
.interactive-map__geo-location {
    position: absolute;
    top: calc(10rem/16);
    left:0;
    right:0;
    margin: 0 auto;
    background-color: #fff;
    z-index: 1;
    box-shadow: none;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(11rem/16);
    letter-spacing: calc(2rem/16);
    padding: calc(13rem/16) calc(22rem/16);
    border: 2px solid var(--color-primary);
    @media screen and (min-width: 768px) {
        display:none;
    }
}
.interactive-map__geo-location__wrapper {
    position: relative;
    margin-left: var(--interactive-map-filter-width);
}
.interactive-map__google-map {
    height: calc(800rem/16);
    width: 100%;
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(700rem/16);
    }
}
.interactive-map__map-canvas {
    width: 100%;
    height: 100%;
    position: absolute;
}

/* Contwise map */
@media screen and (max-width: 767px) {
/*    .contwise-map__iframe {*/
/*        height: 800px !important;*/
/*!* position: relative!important;*!*/
/*    }*/
    .contwise-map__overlay{
        padding-top: 2rem;
    }
    .embed-responsive-contwise-map-xs::before {
        padding-top: 152%!important;
    }
}

.wishlist-btn {
    background-color: #fff;
    border-radius: 50%;
    width: calc(35rem/16);
    height: calc(35rem/16);
    color: var(--color-primary);
    font-size: calc(17rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
}
.wishlist-btn--primary {
    background-color: var(--color-primary-light);
    color:#fff;
}
.wishlist-btn.is-active .icon:before {
    content: var(--icon-star-filled);
}
.wishlist-btn--big.is-active .icon:before {
    content: var(--icon-star-filled);
}
.wishlist-btn--big.is-active .wishlist-btn__text-active {
    opacity:1;
    display: inline-block;
}
.wishlist-btn--big.is-active .wishlist-btn__text-default, .wishlist-btn--big .wishlist-btn__text-active{
    opacity:0;
    display: none;
}
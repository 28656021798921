@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1742378014714/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1742378014714/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1742378014714/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-long:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-badge:before { content: "\EA06" }
.icon-baggage:before { content: "\EA07" }
.icon-bankett:before { content: "\EA08" }
.icon-bed:before { content: "\EA09" }
.icon-bike:before { content: "\EA0A" }
.icon-calendar:before { content: "\EA0B" }
.icon-cart:before { content: "\EA0C" }
.icon-check:before { content: "\EA0D" }
.icon-checkmark-outline:before { content: "\EA0E" }
.icon-checkmark:before { content: "\EA0F" }
.icon-cinema:before { content: "\EA10" }
.icon-clock:before { content: "\EA11" }
.icon-close-thin:before { content: "\EA12" }
.icon-close:before { content: "\EA13" }
.icon-cocktail:before { content: "\EA14" }
.icon-corona:before { content: "\EA15" }
.icon-credit-card:before { content: "\EA16" }
.icon-cutlery:before { content: "\EA17" }
.icon-delete:before { content: "\EA18" }
.icon-difficulty:before { content: "\EA19" }
.icon-distance:before { content: "\EA1A" }
.icon-download:before { content: "\EA1B" }
.icon-electricity:before { content: "\EA1C" }
.icon-elements:before { content: "\EA1D" }
.icon-email:before { content: "\EA1E" }
.icon-expand:before { content: "\EA1F" }
.icon-eye:before { content: "\EA20" }
.icon-facebook:before { content: "\EA21" }
.icon-fax:before { content: "\EA22" }
.icon-flower:before { content: "\EA23" }
.icon-gastronomy:before { content: "\EA24" }
.icon-hat:before { content: "\EA25" }
.icon-heart-outline:before { content: "\EA26" }
.icon-heart:before { content: "\EA27" }
.icon-hiking:before { content: "\EA28" }
.icon-house:before { content: "\EA29" }
.icon-info:before { content: "\EA2A" }
.icon-infrastructure:before { content: "\EA2B" }
.icon-instagram:before { content: "\EA2C" }
.icon-link-external:before { content: "\EA2D" }
.icon-link:before { content: "\EA2E" }
.icon-linkedin:before { content: "\EA2F" }
.icon-list:before { content: "\EA30" }
.icon-login:before { content: "\EA31" }
.icon-mail:before { content: "\EA32" }
.icon-map-pin:before { content: "\EA33" }
.icon-map:before { content: "\EA34" }
.icon-menu:before { content: "\EA35" }
.icon-minus:before { content: "\EA36" }
.icon-mountain:before { content: "\EA37" }
.icon-parking:before { content: "\EA38" }
.icon-parliament:before { content: "\EA39" }
.icon-path-closed:before { content: "\EA3A" }
.icon-path-in-preparation:before { content: "\EA3B" }
.icon-path-open-not-prepared:before { content: "\EA3C" }
.icon-path-open:before { content: "\EA3D" }
.icon-path-partially-open:before { content: "\EA3E" }
.icon-pause:before { content: "\EA3F" }
.icon-pen:before { content: "\EA40" }
.icon-phone-round:before { content: "\EA41" }
.icon-phone:before { content: "\EA42" }
.icon-picture-landscape:before { content: "\EA43" }
.icon-picture-portrait:before { content: "\EA44" }
.icon-pin-regions:before { content: "\EA45" }
.icon-pin:before { content: "\EA46" }
.icon-pinterest:before { content: "\EA47" }
.icon-play-round:before { content: "\EA48" }
.icon-play:before { content: "\EA49" }
.icon-plus-thin:before { content: "\EA4A" }
.icon-plus:before { content: "\EA4B" }
.icon-presentation:before { content: "\EA4C" }
.icon-recycle-bin:before { content: "\EA4D" }
.icon-register:before { content: "\EA4E" }
.icon-route-end:before { content: "\EA4F" }
.icon-route-start:before { content: "\EA50" }
.icon-search:before { content: "\EA51" }
.icon-shoe:before { content: "\EA52" }
.icon-sightseeing:before { content: "\EA53" }
.icon-smartphone:before { content: "\EA54" }
.icon-speech-bubble:before { content: "\EA55" }
.icon-stage:before { content: "\EA56" }
.icon-star-filled:before { content: "\EA57" }
.icon-star:before { content: "\EA58" }
.icon-strong-electricity:before { content: "\EA59" }
.icon-tag:before { content: "\EA5A" }
.icon-theater:before { content: "\EA5B" }
.icon-theatre:before { content: "\EA5C" }
.icon-tiktok:before { content: "\EA5D" }
.icon-toilet:before { content: "\EA5E" }
.icon-traffic:before { content: "\EA5F" }
.icon-tv:before { content: "\EA60" }
.icon-twitter:before { content: "\EA61" }
.icon-u-style:before { content: "\EA62" }
.icon-umbrella:before { content: "\EA63" }
.icon-upload:before { content: "\EA64" }
.icon-voucher:before { content: "\EA65" }
.icon-water-quality:before { content: "\EA66" }
.icon-water-temperature:before { content: "\EA67" }
.icon-water:before { content: "\EA68" }
.icon-weather:before { content: "\EA69" }
.icon-webcams:before { content: "\EA6A" }
.icon-wellness:before { content: "\EA6B" }
.icon-whatsapp:before { content: "\EA6C" }
.icon-youtube:before { content: "\EA6D" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-long: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-badge: "\EA06";
    --icon-baggage: "\EA07";
    --icon-bankett: "\EA08";
    --icon-bed: "\EA09";
    --icon-bike: "\EA0A";
    --icon-calendar: "\EA0B";
    --icon-cart: "\EA0C";
    --icon-check: "\EA0D";
    --icon-checkmark-outline: "\EA0E";
    --icon-checkmark: "\EA0F";
    --icon-cinema: "\EA10";
    --icon-clock: "\EA11";
    --icon-close-thin: "\EA12";
    --icon-close: "\EA13";
    --icon-cocktail: "\EA14";
    --icon-corona: "\EA15";
    --icon-credit-card: "\EA16";
    --icon-cutlery: "\EA17";
    --icon-delete: "\EA18";
    --icon-difficulty: "\EA19";
    --icon-distance: "\EA1A";
    --icon-download: "\EA1B";
    --icon-electricity: "\EA1C";
    --icon-elements: "\EA1D";
    --icon-email: "\EA1E";
    --icon-expand: "\EA1F";
    --icon-eye: "\EA20";
    --icon-facebook: "\EA21";
    --icon-fax: "\EA22";
    --icon-flower: "\EA23";
    --icon-gastronomy: "\EA24";
    --icon-hat: "\EA25";
    --icon-heart-outline: "\EA26";
    --icon-heart: "\EA27";
    --icon-hiking: "\EA28";
    --icon-house: "\EA29";
    --icon-info: "\EA2A";
    --icon-infrastructure: "\EA2B";
    --icon-instagram: "\EA2C";
    --icon-link-external: "\EA2D";
    --icon-link: "\EA2E";
    --icon-linkedin: "\EA2F";
    --icon-list: "\EA30";
    --icon-login: "\EA31";
    --icon-mail: "\EA32";
    --icon-map-pin: "\EA33";
    --icon-map: "\EA34";
    --icon-menu: "\EA35";
    --icon-minus: "\EA36";
    --icon-mountain: "\EA37";
    --icon-parking: "\EA38";
    --icon-parliament: "\EA39";
    --icon-path-closed: "\EA3A";
    --icon-path-in-preparation: "\EA3B";
    --icon-path-open-not-prepared: "\EA3C";
    --icon-path-open: "\EA3D";
    --icon-path-partially-open: "\EA3E";
    --icon-pause: "\EA3F";
    --icon-pen: "\EA40";
    --icon-phone-round: "\EA41";
    --icon-phone: "\EA42";
    --icon-picture-landscape: "\EA43";
    --icon-picture-portrait: "\EA44";
    --icon-pin-regions: "\EA45";
    --icon-pin: "\EA46";
    --icon-pinterest: "\EA47";
    --icon-play-round: "\EA48";
    --icon-play: "\EA49";
    --icon-plus-thin: "\EA4A";
    --icon-plus: "\EA4B";
    --icon-presentation: "\EA4C";
    --icon-recycle-bin: "\EA4D";
    --icon-register: "\EA4E";
    --icon-route-end: "\EA4F";
    --icon-route-start: "\EA50";
    --icon-search: "\EA51";
    --icon-shoe: "\EA52";
    --icon-sightseeing: "\EA53";
    --icon-smartphone: "\EA54";
    --icon-speech-bubble: "\EA55";
    --icon-stage: "\EA56";
    --icon-star-filled: "\EA57";
    --icon-star: "\EA58";
    --icon-strong-electricity: "\EA59";
    --icon-tag: "\EA5A";
    --icon-theater: "\EA5B";
    --icon-theatre: "\EA5C";
    --icon-tiktok: "\EA5D";
    --icon-toilet: "\EA5E";
    --icon-traffic: "\EA5F";
    --icon-tv: "\EA60";
    --icon-twitter: "\EA61";
    --icon-u-style: "\EA62";
    --icon-umbrella: "\EA63";
    --icon-upload: "\EA64";
    --icon-voucher: "\EA65";
    --icon-water-quality: "\EA66";
    --icon-water-temperature: "\EA67";
    --icon-water: "\EA68";
    --icon-weather: "\EA69";
    --icon-webcams: "\EA6A";
    --icon-wellness: "\EA6B";
    --icon-whatsapp: "\EA6C";
    --icon-youtube: "\EA6D";
    
}
.swiper-button {
    width: calc(55rem/16);
    height: calc(55rem/16);
    background-color:#fff;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border:none;
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}
.swiper-container-horizontal>.swiper-scrollbar {
    height: calc(8rem/16);
    left:0;
    right:0;
}
.swiper-scrollbar__wrapper {
    width: calc(450rem/16);
    position: absolute;
    bottom:0;
    left: 0;
    right:0;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.swiper-scrollbar {
    border-radius: 0;
    background:#fff;
    height: calc(8rem/16);
}
.swiper-scrollbar-drag {
    border-radius:0;
    background:var(--color-primary-light);
}
.swiper--same-height .swiper-slide{
    height: auto;
}
.swiper-button.swiper-button-disabled {
    opacity:0.5;
    pointer-events: none;
}
.alert-notification {
    z-index: 99;
    padding:0;
    width: auto;
    height: auto;
    margin-bottom:0;
}
.alert-notification__container {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.3);
    background-color: #fff;
}
.press-picture-teaser {
    border: 1px solid var(--color-light-grey);
    position: relative;
}

.press-picture-teaser--album{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.press-picture-teaser__wishlist-btn.wishlist-btn {
    position: absolute !important;
    z-index: 9 !important;
    top: calc(20rem/16);
    right: calc(20rem/16);
}
.press-picture-teaser__img {
    padding: calc(20rem/16);
}

.press-picture-teaser--album .press-picture-teaser__img{
    border-bottom: 1px solid var(--color-light-grey);
}

.press-picture-teaser__body {
    border-top: 1px solid var(--color-light-grey);
    padding: calc(10rem/16);
    @media screen and (min-width: 768px){
        padding: calc(20rem/16);
    }
}

.press-picture-teaser--album .press-picture-teaser__body{
    border-top: none;
}

.press-picture-teaser__icon {
    color: var(--color-primary);
    font-size: calc(30rem/16);
    vertical-align: calc(-3rem/16);
}
.press-picture-teaser__list-item {
    vertical-align: middle;
}
.press-picture-teaser__title {
    margin-top: calc(10rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(21/16);
    margin-bottom: calc(15rem/16);
}
.press-picture-teaser__copyright {
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
}

.press-picture-teaser .btn-download{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        padding: calc(13rem/16) calc(10rem/16);
        font-size: calc(11rem/16);
        letter-spacing: calc(1rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(40rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(9rem/16);
        letter-spacing: calc(1rem/16);
        padding: calc(10rem/16);
    }
}

.press-picture-teaser .btn-icon{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}
.region-hotspots {
    position: relative;
    overflow: hidden;
}
.region-hotspots__wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
}
.region-hotspots__hotspot{
    position: relative;
    z-index: 9;
    opacity:0;
    transform: scaleX(0);
    background-color: var(--color-primary-light);
    border-radius: 50%;
    width: calc(15rem/16);
    height: calc(15rem/16);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.4s ease, transform 0.4s ease;

    @media screen and (max-width: 767px) {
        height: calc(7rem/16);
        width: calc(7rem/16);
    }
}
.region-hotspots__hotspot.active {
    opacity:1;
    transform: scaleX(1);
}
.region-hotspots__hotspot:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    opacity: 0.2;
    box-shadow: 0 0 0 0 rgba(3, 116, 51, 0.6);
    transform: scale(5);
}

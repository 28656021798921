.pagination-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
}
.pagination-container:before,
.pagination-container:after {
    content: '';
    display: flex;
    flex-grow: 1;
    height: 1px;
    background: currentColor;
    opacity: .4;
}
.pagination {
    margin: 0 calc(8rem / 16);
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0 calc(20rem / 16);
        font-size: calc(20rem / 16);
    }
}

.is-map .pagination-container{
    opacity: 0;
    pointer-events: none;
}

.page-item:not(:first-child) {
    margin-left: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(14rem / 16);
    }
}

.page-link {
    padding: calc(3rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(14rem / 16);
    }
}

.page-link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(3rem / 16);
    background: currentColor;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        height: calc(4rem / 16);
    }
}
.page-item.active .page-link:after {
    transform: scaleX(1);
}

.page-link--text {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
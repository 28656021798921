.select {
    position: relative;
    color: #fff;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select__input-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    color: currentColor;
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(12rem / 16);
    top: 50%;
    right: calc(15rem / 16);
    transform: translateY(-50%);
    z-index: 1;

    @media screen and (min-width: 768px) {
        right: calc(26rem / 16);
    }
}
.select--hide-arrow .select__input-wrapper:before {
    content:'';
}
.experience-slider__container {
    padding-top: calc(50rem/16);
    padding-bottom: calc(120rem/16);
    padding-left: calc(80rem/16);
    padding-right: calc(32rem/16);
    margin-left: calc(-48rem/16);
    pointer-events: none;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(20rem/16) calc(60rem/16);
        margin-left:calc(-15rem/16);
        margin-right:calc(-15rem/16);
    }
}
.experience-slider__swiper-slide.swiper-slide-active, .experience-slider__swiper-slide.swiper-slide-next,
.experience-slider__swiper-slide.swiper-slide-active + .experience-slider__swiper-slide {
    pointer-events: auto;
}
.experience-slider__btn {
    position: absolute;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.experience-slider__btn.swiper-button--next {
    right:0;
    top: 50%;
    transform: translateY(-50%) translateX(58%);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.experience-slider__btn.swiper-button--prev {
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-58%) rotate(180deg);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.experience-slider {
    position: relative;
    @media screen and (max-width: 1299px) and (min-width: 768px){
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
}
.experience-slider__btn.swiper-button-disabled {
    opacity:0;
}
.experience-slider__swiper-slide.swiper-slide-active .experience-teaser {
    transform: scale(1.2);
    box-shadow: 0 30px 40px 0 rgba(32,32,32,0.8);
    @media screen and (max-width: 767px) {
        transform: scale(1);
        box-shadow: 0 10px 30px 0 rgba(32,32,32,0.8);
    }
}
.experience-slider__swiper-slide:not(.swiper-slide-active) .experience-teaser {
    transform: translateX(10%);
    @media screen and (max-width: 767px) {
        transform: none;
    }
}
.experience-slider__swiper-slide.swiper-slide-prev .experience-teaser {
    transform: translateX(-20%);
    @media screen and (max-width: 767px) {
        transform: none;
    }
}
.experience-slider__swiper-slide.swiper-slide-active .experience-teaser__counter {
    opacity:1;
    transform: scale(1) translateX(-70px);
    @media screen and (max-width: 767px) {
        transform: scale(1) translateX(-10px);
    }
}
.experience-slider__swiper-slide {
    @media screen and (min-width: 768px) {
        transform: translateX(-107%);
    }
}
.experience-slider__swiper-scrollbar__wrapper {
    @media screen and (max-width: 767px) {
        padding: 0 calc(20rem/16);
    }
}
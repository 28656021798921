.info-modal__dialog{
    max-width: calc(703rem/16);
    padding: 0 calc(4rem/16);
    @media screen and (min-width: 768px){
        padding: 0 calc(14rem/16);
    }
}
.info-modal__content{
    background: #FFF;
    border: none;
    border-radius: 0;
}
.info-modal__body{
    padding: 0;
}
.info-modal__close{
    position: absolute;
    top: calc(20rem/16);
    right: calc(10rem/16);
    font-size: calc(14rem/16);
    color: #FFF;
    z-index: 1;
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        top: calc(30rem/16);
        right: calc(10rem/16);
    }
}
.info-modal__close:after{
    content: '';
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: calc(40rem/16);
    height: calc(40rem/16);
    opacity: 0.2;
    z-index: -1;
}
.info-modal__close:hover{
    color: #FFF;
}
.info-modal__container{
    padding: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(40rem/16) calc(95rem/16);
    }
}
.info-modal__title{
    font-size: calc(20rem/16);
    line-height: calc(18/20);
}
.info-modal__text {
    font-size: calc(14rem/16);
    line-height: calc(25/14);
}
.webcam-teaser {
    position: relative;
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(32,32,32,0.6);
    color: #fff;
}
.webcam-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    background-color: rgba(32,32,32,0.6);
    transition: opacity 0.2s ease;
}
.webcam-teaser:hover:after {
    opacity: 0.3;
}
.webcam-teaser__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(20rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(90rem / 16);
    }
}
.webcam-teaser__content__title {
    letter-spacing: calc(2.5rem / 16);
}
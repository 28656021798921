.download-item {
    background-color: var(--color-secondary);
    color:#fff;
    display: flex;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.5);
    transition: background-color 0.2s ease;
}
.download-item__img {
    width: calc(120rem/16);
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
    }
}
.download-item__img:after {
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content:'';
    position: absolute;
    transition: opacity 0.2s ease;
    background: linear-gradient(90deg, rgba(32,32,32,0.1) 0%, rgba(32,32,32,0.7) 100%);
}
.download-item:hover .download-item__img:after {
    opacity: 0.1;
}
.download-item:hover {
    background-color: var(--color-secondary-light);
}
.download-item__body {
    padding: calc(33rem/16) calc(50rem/16);
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        font-size: calc(10rem/16);
    }
}
.download-item__title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(18rem/16);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: 1.1;
    }
}
.download-item__btn {
    background-color: var(--color-secondary-light);
    color:#fff;
    width: calc(110rem/16);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    font-size: calc(10rem/16);
    letter-spacing: calc(1.82rem/16);
    @media screen and (max-width: 767px) {
        width: calc(54rem/16);
        font-size: calc(8rem/16);
        letter-spacing: calc(0.2rem/16);
    }
}
.download-item__btn--view {
    background-color: var(--color-primary-light);
}
.download-item__btn-icon {
    font-size: calc(26rem/16);
    transition: transform 0.3s ease;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        margin-bottom: calc(10rem/16);
    }
}
@media screen and (min-width: 768px) {
    .download-item__btn:hover .download-item__btn-icon {
        transform: translateY(10px);
    }
}
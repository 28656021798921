/* Teaser Grid */
.demi-infrastructure-teaser-grid,
.demi-event-teaser-grid,
.demi-additionalservice-teaser {
    display: flex;
    flex-direction: column;
    flex: auto;
    cursor: pointer;
    border-radius: .3125rem;
    position:relative;
    box-shadow: 0 9px 15px 0 rgba(32,32,32,.6);
    overflow: hidden;
    position: relative;
}
.demi-infrastructure-teaser-grid__img:before,
.demi-event-teaser-grid__img:before,
.demi-additionalservice-teaser__img:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: -.0625rem;
    z-index: 1;
    left: 0;
    opacity: 1;
    background: linear-gradient(180deg,rgba(0,54,16,0), rgba(0, 54, 16, 0.44), #003610);
    transition: transform .3s ease,opacity .3s ease;
    transform-origin: bottom;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.demi-infrastructure-teaser-grid__img,
.demi-event-teaser-grid__img,
.demi-additionalservice-teaser__img{
    position: relative;
    overflow: hidden;
}
.demi-infrastructure-teaser-grid__img .embed-responsive-item,
.demi-event-teaser-grid__img .embed-responsive-item,
.demi-additionalservice-teaser__img .embed-responsive-item{
    transition: transform 0.4s ease;
    object-fit:cover
}
@media screen and (min-width: 768px) {
    .demi-infrastructure-teaser-grid:hover .demi-infrastructure-teaser-grid__img:before,
    .demi-event-teaser-grid:hover .demi-event-teaser-grid__img:before,
    .demi-additionalservice-teaser:hover .demi-additionalservice-teaser__img:before{

    }
    .demi-infrastructure-teaser-grid:hover .demi-infrastructure-teaser-grid__img .embed-responsive-item,
    .demi-event-teaser-grid:hover .demi-event-teaser-grid__img .embed-responsive-item,
    .demi-additionalservice-teaser:hover .demi-additionalservice-teaser__img .embed-responsive-item{
        transform: scale(1.07);
    }
}

.demi-infrastructure-teaser-grid__body,
.demi-event-teaser-grid__body,
.demi-additionalservice-teaser__body {
    padding: .5rem .5rem .75rem .5rem;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-primary);
    color: #fff;

    @media screen and (min-width: 992px) {
        padding: 1.25rem;
    }

    @media screen and (min-width: 768px) {
        padding: .5rem;
    }
}
.demi-infrastructure-teaser-grid__body,
.demi-event-teaser-grid__body{
    align-items: flex-start;
}
.demi-infrastructure-teaser-grid__body .badge {
    white-space: unset;
    text-align: left;
}
.demi-infrastructure-teaser-grid__title,
.demi-event-teaser-grid__title,
.demi-additionalservice-teaser__title {
    font-size: calc(14rem / 16);
    margin-bottom: .5rem;
    font-family: var(--font-decorative);
    color: #fff;
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
        margin-bottom: 1.25rem;
    }

    @media screen and (max-width: 767px) {
        word-break: break-word;
    }
}
.demi-infrastructure-teaser-grid__title a:before,
.demi-event-teaser-grid__title a:before,
.demi-additionalservice-teaser__title a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}
.demi-additionalservice-teaser__title {
    color: #fff;

    @media screen and (min-width: 768px) {
        margin-bottom: .5rem;
    }
}

.demi-event-teaser-grid__dates {
    margin-bottom: .5rem;
    position: absolute;
    top: 60px;
    background: #fff;
    padding: 7px;
    font-family: var(--font-decorative);
    color: var(--color-primary);
    right: 10px;
    display: inline-block;
    width: auto;
    line-height: 1;
    @media screen and (min-width: 768px) {
        margin-bottom: 1.25rem;
    }
}

.demi-infrastructure-teaser-grid__btn,
.demi-event-teaser-grid__btn,
.demi-additionalservice-teaser__btn {
    background-color:#fff;
    color: var(--color-primary);
    margin-top: calc(10rem/16);
    width: 100%;
    display:block;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding: .5rem .75rem;
        font-size: 1rem;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(13rem / 16);
        padding: .25rem .5rem;
    }
}
.demi-event-teaser-grid .wysiwyg {
    margin-bottom: calc(10rem/16);
}
.demi-event-teaser-grid__btn {
    margin-top: auto;
}
.demi-additionalservice-teaser__price {
    font-family: var(--font-default-medium);
    font-weight: var(--font-default-medium-weight);
    color: #fff;
}
.demi-event-teaser-grid__inner-body {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
    width: 100%;
}
.demi-infrastructure-teaser-grid__young-styria-logo,
.demi-event-teaser-grid__young-styria-logo {
    position: absolute;
    width: calc(140rem/16);
    top: calc(10rem/16);
    z-index: 1;
    right: calc(10rem/16);
}
.demi-event-overview-teaser-list {
    display: flex;
    justify-content: center;
}
.demi-additionalservice-teaser__price {
    font-size: calc(24rem/16);
    font-family: var(--font-decorative);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.demi-additionalservice-teaser__price-label {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.demi-event-teaser-grid__body .badge {
    white-space: normal;
    text-align: left;
}

.demi-infrastructure-teaser-grid__icon-info{
    position: absolute;
    top: calc(10rem/16);
    left: calc(10rem/16);
    border-radius: 1000px;
    background-color: #fff;
    color: var(--color-primary);
    font-size: calc(11rem/16);
    letter-spacing: calc(2rem/16);
    padding: calc(5rem/16);
    height: calc(35rem/16);
    z-index: 1;
    display: flex;
    align-items: center;
    font-family: var(--font-default-bold);
}

.demi-infrastructure-teaser-grid__icon-info-icon{
    border-radius: 50%;
    width: calc(27rem/16);
    height: calc(27rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--color-primary);
    margin-right: calc(7rem/16);
    font-size: calc(10rem/16);
    letter-spacing: 0;
}

.info-card {
    position: relative;
    background-color: white;
    color: var(--color-primary);
    padding: calc(15rem/16) calc(27rem/16);
    box-shadow: 0 calc(15rem/16) calc(30rem/16) rgba(0,0,0,.1);
    margin-bottom: calc(20rem/16);
    break-inside: avoid-column;
}
.info-card::before {
    content: '';
    position: absolute;
    left: 0;
    right: auto;
    top: 0;
    bottom: 0;
    width: calc(6rem/16);
    background-color: var(--color-primary-light);
}
.info-card__title {
    font-family: var(--font-decorative);
    font-size: calc(18rem/16);
    line-height: calc(27rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        line-height: calc(24rem/16);
    }
}
.info-card__subtitle {
    font-size: calc(16rem/16);
    line-height: calc(26rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        line-height: calc(21rem/16);
    }
}
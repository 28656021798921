.dropdown-toggle--select {
    border: 0;
    box-shadow: none;
    width: 100%;
    text-align: left;
    padding: calc(13rem / 16) calc(40rem / 16) calc(13rem / 16) calc(25rem / 16);
    color: #fff;
    border-radius: calc(30rem / 16);
    transition: background 200ms ease-in-out;
}
.dropdown-toggle--select:after {
    content: var(--icon-arrow-down);
    font-family: iconfont;
    border: 0;
    margin: 0;
    position: absolute;
    transform: translateY(-50%);
    font-size: calc(14rem / 16);
    top: 50%;
    right: calc(26rem / 16);
}
.dropdown-toggle--select + .dropdown-menu {
    min-width: calc(100% - calc(40rem / 16));
    margin: 0 calc(20rem / 16);
}

/* colors */
.dropdown-secondary .dropdown-toggle,
.dropdown-secondary .dropdown-menu {
    background: var(--color-secondary);
    color: #fff;
}
.dropdown-secondary .dropdown-item {
    color: currentColor;
}
.dropdown-secondary .dropdown-toggle:hover,
.dropdown-secondary .dropdown-item:hover {
    background: var(--color-secondary-light);
}
.dropdown-toggle:focus {
    outline: none;
}
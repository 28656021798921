.mountain-weather-teaser {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    color:#fff;
    overflow: hidden;
}
.mountain-weather-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transform-origin: bottom;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background: linear-gradient(0deg,#0e2414,#0e2414 50%,rgba(14,36,20,0.9) 60%,rgba(14,36,20,0.7));
}
.mountain-weather-teaser:hover:before {
    opacity: 0.8;
}
.mountain-weather__icon {
    font-size: calc(65rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(86rem / 16);
    }
}
.mountain-weather__item {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(-4rem / 16);
}
.mountain-weather__item--bottom {
    top: auto;
    bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        bottom: calc(14rem / 16);
    }
}
.mountain-weather__item__value {
    font-size: calc(18rem / 16);
    line-height: calc(26/22);
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}
.mountain-weather__item__value:before {
    content: '';
    width: 100%;
    border-top: calc(2rem / 16) dashed #fff;
    opacity: 0.5;
    margin: calc(2rem / 16) calc(8rem / 16) 0;
}
.mountain-weather-teaser__body {
    position: relative;
    padding: calc(18rem / 16) calc(15rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(44rem / 16) calc(40rem / 16);
    }
}
.custom-video-controls{
    position: absolute;
    bottom: calc(20rem/16);
    left: calc(20rem/16);
    background: none;
    border-radius: calc(5rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(15rem/16);
    border: solid calc(2rem/16) var(--color-primary-light);
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
}
.custom-video-controls:hover,
.custom-video-controls:focus-visible{
    background: #FFFFFF;
    border-color: var(--color-primary);
}
.custom-video-controls:focus{
    outline: none;
}
.custom-video-controls .custom-video-controls__play,
.custom-video-controls .custom-video-controls__pause{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-primary);
}
.custom-video-controls.is-playing .custom-video-controls__play{
    display: none;
}
.custom-video-controls:not(.is-playing) .custom-video-controls__pause{
    display: none;
}
html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;
    @media screen and (max-width: 767px) {
        font-size: var(--font-size-default--mobile);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: 700;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(40rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
h2, .h2 {
    font-size: calc(28rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
h3, .h3 {
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(15rem/16);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
h6, .h6 {
    font-size: 1em;
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
    }
}

table{
    word-break: initial;
}
.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: var(--color-primary-light);
    scrollbar-width: thin;
    padding-bottom: calc(4rem / 16);
}
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.scrolling-area::-webkit-scrollbar {
    width: calc(4rem / 16);
    height: calc(7rem / 16);
    background: var(--color-light-grey);
    transition: all 120ms ease;
}

.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-dark);
    transition: all 120ms ease;
}

.scrolling-area--light::-webkit-scrollbar {
    background: #fff;
    transition: all 120ms ease;
}

.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-light);
    transition: all 120ms ease;
}

@media screen and (max-width: 767px) {
    .scrolling-area--hide-mobile::-webkit-scrollbar-track, .scrolling-area--hide-mobile::-webkit-scrollbar {
        display: none;
        height:0;
    }
    .scrolling-area--mobile {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: calc(4rem / 16);
    }
    .scrolling-area--mobile::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.3);
        background-color: transparent;
        transition: all 120ms ease;
    }

    .scrolling-area--mobile::-webkit-scrollbar {
        width: calc(4rem / 16);
        height: calc(4rem / 16);
        background: var(--color-light-grey);
        transition: all 120ms ease;
    }

    .scrolling-area--mobile::-webkit-scrollbar-thumb {
        background-color: var(--color-primary-dark);
        transition: all 120ms ease;
    }
    .scrolling-area--mobile::-webkit-scrollbar-thumb {
        background-color: var(--color-dark-grey);
        transition: all 120ms ease;
    }
}

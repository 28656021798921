.badge{
    font-size: calc(11rem/16);
    padding: calc(5rem/16) calc(6rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        padding: calc(6rem/16) calc(8rem/16);
    }
}

.badge-white-outline {
    border: 1px solid rgb(255 255 255 / 66%);
}
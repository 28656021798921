.references-upload-block {
    margin-bottom: calc(50rem/16);
}
.references-upload-block__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(20rem/16);
    font-family: var(--font-decorative);
    font-size: calc(16rem/16);
    line-height: 1;
    color: white;
}
.references-upload-block__delete-row {
    position: absolute;
    top: 23%;
    right: calc(30rem/16);
}
.references-upload-block__delete-row:hover {
    cursor: pointer;
}
.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + iframe + iframe + .content-block,
.content-block + iframe +.content-block
{
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
    }
}
.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}
.content-visibility--auto .pimcore_area_content:not(.pimcore_area_media-img-text-slide) {
    contain-intrinsic-size: 1px;
    content-visibility: auto;
}

#main-content > .content-block:last-child.content-block--no-footer-margin,
.pimcore_area_content:last-child .content-block.content-block--no-footer-margin {
    margin-bottom: calc(-40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-100rem/16);
    }
}

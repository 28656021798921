.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}
.vertical-gutter--80 {
    margin-top: 0.5rem;
}
.vertical-gutter--80 > .vertical-gutter__item {
    margin-top: 0.5rem;
}
.vertical-gutter--default {
    margin-top: calc(var(--gutter) * -1);
    @media screen and (max-width: 767px) {
        margin-top: calc(var(--gutter-mobile) * -1);
    }
}
.vertical-gutter--default > .vertical-gutter__item {
    margin-top: calc(var(--gutter));
    @media screen and (max-width: 767px) {
        margin-top: calc(var(--gutter-mobile));
    }
}

@media screen and (min-width: 768px){
    .vertical-gutter-md--default {
        margin-top: calc(var(--gutter) * -1);
    }
    .vertical-gutter-md--default > .vertical-gutter__item {
        margin-top: calc(var(--gutter));
    }
    .vertical-gutter--80 {
        margin-top: calc(-80rem/16);
    }
    .vertical-gutter--80 > .vertical-gutter__item {
        margin-top: calc(80rem/16);
    }
}

/* Teaser List */
.demi-infrastructure-teaser-list,
.demi-event-teaser-list {
    cursor: pointer;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);

    @media screen and (max-width: 767px) {
        padding: .5rem .25rem;
    }
}

.demi-infrastructure-teaser-list.row,
.demi-event-teaser-list.row {
    @media screen and (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-left: .25rem;
        margin-right: .25rem;
    }
}

.demi-infrastructure-teaser-list.row > [class*=" col"],
.demi-infrastructure-teaser-list.row > [class^=col],
.demi-event-teaser-list > [class*=" col"],
.demi-event-teaser-list > [class^=col] {
    @media screen and (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
    @media screen and (max-width: 767px) {
        padding-left: .25rem;
        padding-right: .25rem;
    }
}

.demi-event-teaser-list__body,
.demi-infrastructure-teaser-list__body {
    background: #ffffff;
    padding-left: .25rem;

    @media screen and (min-width: 768px) {
        padding: 1.5rem;
    //padding-left: 2rem;
    }
}

.demi-infrastructure-teaser-list__title,
.demi-event-teaser-list__title {
    text-transform: none;
}

.demi-infrastructure-teaser-list__body p {
    margin: 0;
}

.demi-infrastructure-teaser-list__btn,
.demi-event-teaser-list__btn {
    margin-top: .5rem;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        padding: .25rem 1rem;
        font-size: 1rem;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(13rem / 16);
        padding: .25rem .5rem;
    }
}
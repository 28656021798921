:root {
    --mega-nav-level-0-item-spacing: calc(16rem / 16);
}

@media (hover: none) {
    .mega-nav__item-content--level-0 {
        display: flex;
    }
}

@media screen and (min-width: 1700px) {
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0:before,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0:before {
        transform: scaleX(4);
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0:after,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0:after{
        transform: translateX(119px);
        z-index: 99;
    }
    .mega-nav__item-content--level-0 {
        font-size: calc(26rem / 16);
        letter-spacing: calc(6.5rem/16);
    }
}
@media screen and (min-width: 1200px){
    .mega-nav__list--level-0 {
        width: 100%;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1699px) {
    .mega-nav__item-content--level-0 {
        font-size: calc(22rem/16);
        letter-spacing: calc(4.5rem/16);
    }
}

@media screen and (min-width: 1699px){
    .mega-nav--level-0 {
        padding-left: 10%;
        width: 48.5%;
    }
}

@media screen and (min-width: 768px) {
    .mega-nav--level-0 {
        display: flex;
        flex: auto;
        align-items: flex-start;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        width: 36.5%;
        padding-left: 0;
        padding-top: 50px;
    }
    .mega-nav__list--level-0 {
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
        max-height: 70vh;
        padding-right: 27%;

        padding-left: calc(35rem/16);
        padding-top: calc(20rem/16);
        padding-bottom: calc(20rem/16);

        margin-left: calc(-35rem/16);
        margin-top: calc(-20rem/16);
        margin-bottom: calc(-20rem/16);
    }
    .mega-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: static;
    }
    .mega-nav__item--level-0 + .mega-nav__item--level-0 {
        margin-top: calc(56rem/16);
    }

    .mega-nav__item-content--level-0 {
        flex-direction: column;
        position: relative;
        flex: auto;
        padding: 1rem var(--mega-nav-level-0-item-spacing);
        font-size: calc(17rem/16);
        letter-spacing: calc(2.5rem/16);
        text-transform: uppercase;
        font-family: var(--font-decorative);
        display: flex;
        justify-content: space-between;
        word-break: normal;
    }
    .mega-nav__item-content--level-0:before {
        content:'';
        position: absolute;
        width: calc(51rem/16);
        height: calc(51rem/16);
        z-index: -1;
        background-color: var(--color-primary-light);
        border-radius: 50%;
        top: 45%;
        transform: translate(-50%, -50%) scale(0);
        pointer-events: none;
    }
    .mega-nav__item.is-open > .mega-nav__item-content--level-0:before,
    .mega-nav__item.is-open--only-desktop > .mega-nav__item-content--level-0:before{
        animation: scaleBubble 0.4s ease forwards;
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0:before,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0:before {
        transform: scaleX(2);
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0:after,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0:after {
        transform: translateX(40px);
        z-index: 99;
    }
    .mega-nav__item-content {
        transition: transform 0.2s ease;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color:#fff;
        padding:0;
        line-height:1;
    }
    .mega-nav__item-content--level-0  {
        opacity:0.8;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: opacity 0.2s ease;
        text-align:left;
    }
    .mega-nav__item-content--level-0:hover,
    .mega-nav__item-content--level-0:focus,
    .mega-nav__item-content--level-0:active,
    .mega-nav__item.is-open > .mega-nav__item-content--level-0,
    .mega-nav__item.is-open--only-desktop > .mega-nav__item-content--level-0{
        opacity: 1;
        outline:none;
        transition: opacity 0.2s ease;
    }
    .mega-nav__collapse-icon--level-0 {
        position: relative;
        display: inline-flex;
        align-items:center;
        z-index: 99;
        opacity:0.2;
        padding-right: calc(20rem/16);
        padding-left: calc(15rem/16);
        top: calc(-5rem/16);
    }
    .mega-nav__collapse-icon--level-0:before {
        content:'';
        width: calc(40rem/16);
        border-bottom:2px solid #fff;
        transform:scaleX(1);
        z-index: 99;
        transition: transform 0.3s ease;
        transform-origin: left;
    }
    .mega-nav__item--level-0.is-open .mega-nav__collapse-icon--level-0,
    .mega-nav__item--level-0.is-open--only-desktop .mega-nav__collapse-icon--level-0 {
        opacity:1;
    }
    .mega-nav__collapse-icon--level-0:after {
        content: var(--icon-arrow-right);
        position: absolute;
        display: inline-block;
        z-index: 99;
        font-family: iconfont;
        speak: none;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        transform: translate(0);
        left: calc(50rem/16);
        font-size: calc(17rem/16);
        padding-top:calc(1rem/16);
        transition: transform 0.3s ease;
    }

}


@keyframes scaleBubble {
    0% {  transform: translate(-50%, -50%) scale(0); }
    60% {  transform: translate(-50%, -50%) scale(1.3); }
    100% {  transform: translate(-50%, -50%) scale(1); }
}
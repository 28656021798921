.gallery-item__counter {
    text-align: right;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-muted);
    margin-bottom: calc(6rem/16);
    opacity:0;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.gallery-item__text {
    margin-top: calc(10rem/16);
    color: var(--color-text-muted);
    opacity:0;
    transition: opacity 0.3s ease;
}
.gallery__container {
    overflow: visible;
}
.gallery-swiper-slide {
    transform: scale(0.5);
    transition: transform 0.4s ease;
    @media screen and (max-width: 767px) {
        transform: scale(0.9);
    }
}
.gallery-swiper-slide.swiper-slide-active {
    transform:scale(1);
    pointer-events: auto;
}
.gallery-swiper-slide.swiper-slide-active .gallery-item__text {
    opacity:1;
}
.gallery-swiper-slide.swiper-slide-active .gallery-item__counter {
    opacity:1;
}
.gallery-item__img-wrapper:after {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    right:0;
    top:0;
    z-index: 2;
    transition: opacity 0.4s ease;
    background: #fff;
    opacity: 0.4;
}
.gallery-swiper-slide.swiper-slide-active .gallery-item__img-wrapper:after {
    opacity:0;
}
.gallery__btn {
    position: absolute;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.gallery__btn.swiper-button--next {
    right:0;
    top: 50%;
    transform: translateY(-50%) translateX(58%);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.gallery__btn.swiper-button--prev {
    left:0;
    top: 50%;
    transform: translateY(-50%) translateX(-58%) rotate(180deg);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.gallery {
    position: relative;
    @media screen and (max-width: 1499px) and (min-width: 768px){
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
    @media screen and (max-width: 767px){
        max-width: 80vw;
        margin: 0 auto;
    }
}
.gallery__wrapper {
    pointer-events: none;
}
.facility-info-slide{
    border-top: calc(1rem/16) solid var(--color-grey);
    border-bottom: calc(1rem/16) solid var(--color-grey);
    padding: calc(24rem/16) 0;
    @media screen and (min-width: 768px){
        padding: calc(40rem/16) 0;
    }
}
.facility-info-slide__icon{
    font-size: calc(35rem/16);
    margin-bottom: calc(11rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(42rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.facility-info-slide__number{
    font-size: calc(22rem/16);
    line-height: calc(37/30);
    font-family: var(--font-decorative);
    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
    }
}
.facility-info-slide__number-range{
    font-size: calc(11rem/16);
    line-height: calc(16/13);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }
}
.facility-info-slide__description{
    font-size: calc(10rem/16);
    line-height: calc(14/11);
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: calc(5rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(11rem/16);
    }
}
.facility-info-slide__text{
    font-size: calc(20rem/16);
    line-height: calc(37/20);
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.facility-info-slide__height{
    display: flex;
    justify-content: center;
    gap: calc(24rem/16);
    margin-bottom: calc(14rem/16);
}
.lightbox-teaser {
    position: relative;
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) 0 rgba(32,32,32,0.6);
    color: #fff;
    overflow: hidden;
}
.lightbox-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: calc(-1rem / 16);
    z-index: 1;
    left:0;
    opacity: 0.7;
    background: linear-gradient(180deg, rgba(0,54,16,0) 0%, rgba(0,54,16,1) 100%);
}
.lightbox-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(18rem / 16) calc(20rem / 16);
    z-index: 1;
}
.lightbox-teaser__content__title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(18rem / 16);
}

.region-map svg {
    width: 100%;
    height: 100%;
}
.region-map__item-text {
    font-size: calc(10rem / 16);
    text-transform: uppercase;
    letter-spacing: calc(2rem / 16);
    text-anchor: middle;
}
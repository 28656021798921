.footer-main {
    background-color: var(--color-secondary);
    color:#fff;
    padding-top: calc(94rem/16);
    padding-bottom: calc(110rem/16);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(50rem/16);
        padding-bottom: calc(50rem/16);
    }
}
.footer-main__link {
    text-transform: uppercase;
    font-family: var(--font-decorative);
    margin-bottom: calc(10rem/16);
    display: block;
    transition: color 0.2s ease;
    font-size: calc(14rem/16);
}
.footer-main__link:hover {
    color: var(--color-primary-light);
}
.footer-main__title {
    text-transform: uppercase;
    font-family: var(--font-decorative);
    margin-bottom: calc(20rem/16);
    font-size: calc(14rem/16);
}
.footer-main__address {
    font-size: calc(14rem/16);
}
.footer-main__contact-link {
    display: block;
    text-decoration: underline;
}
.footer-main__contact-link:hover {
    color: var(--color-primary-light);
}
.footer-main__logo {
    max-width: calc(130rem/16);
    margin: 0 auto;
    display: flex;
}
.footer-main__logo--cinestyria {
    max-width: calc(196rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(150rem/16);
    }
}
.footer-main__text {
    letter-spacing: 1.75px;
    max-width: calc(380rem/16);
}
.footer-main__form-control {
    border-radius: 0;
    background-color: var(--color-light-grey);
    color: var(--color-text-default);
    height: 100%;
    @media screen and (max-width: 767px) {
        height: calc(51rem/16);
        margin-bottom: calc(14rem/16);
    }
}
.footer-main__submit-btn {
    @media screen and (max-width: 767px) {
        height: calc(51rem/16);
    }
}

.footer-main__sponsors img {
    height: calc(36rem/16);
    width: auto;
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px) {
        height: calc(80rem/16);
        margin-bottom: 0;
    }
}
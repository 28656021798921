/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline {text-decoration: underline;}

/* Text Color Helper */
.text-white { color: #ffffff !important; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey { color: var(--color-dark-grey); }

/* Background Color Helper */

.fz12 {
    font-size: calc(12rem/16);
}
.fz14 {
    font-size: calc(14rem/16);
}
.fz16 {
    font-size: calc(16rem/16);
}
.fz20 {
    font-size: calc(20rem/16);
}
.fz22 {
    font-size: calc(22rem/16);
}
.fz25 {
    font-size: calc(25rem/16);
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.link {
    color: var(--color-primary-light);
}
.link:hover {
    color: var(--color-primary);
}
.link__icon {
    width: calc(30rem/16);
}
.link--white {
    color: var(--color-white);
}
.link--white:hover {
    color: var(--color-primary-light);
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-auto {
    pointer-events: auto;
}

.font-decorative {
    font-family: var(--font-decorative);
    font-weight: var(--font-weight-decorative);
}

.bg-dark-bg {
    background-color: var(--color-dark-bg);
}
.bg-light-bg {
    background-color: var(--color-light-grey);
}

.img-dropshadow {
    filter: drop-shadow(0 calc(5rem / 16) calc(8rem / 16) rgba(0,0,0,.3));
}

/* collapse button text helper */
.collapsed > .collapse-btn__more {
    display: block;
}
.collapse-btn__more,
.collapsed > .collapse-btn__less {
    display: none;
}

.letter-spacing--5 {
    letter-spacing: calc(5rem/16);
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-xl-auto{
    @media screen and (max-width: 1200px){
        overflow-x: auto;
    }
}
@media print {
    .js-fade-in {
        opacity: 1 !important;
    }
}
.react-select-wrapper {
    width: 100%;
}
.react-select-wrapper {
    height: calc(1.5em + 1.74rem + 2px);
}
.react-select-wrapper .react-select__control {
    height: calc(1.5em + 1.74rem + 2px);
    border: 1px solid #fff;
    border-radius: 1.875rem;
    display: block;
    width: 100%;
    color: #fff;
    font-size: .875rem;
    background-color: var(--color-secondary);
    padding: 9px 15px;
}
.react-select-wrapper .react-select__indicators {
    display:none;
}
.react-select-wrapper .react-select__placeholder, .react-select-wrapper .react-select__input {
    color:#fff;
}
.react-select-wrapper .react-select__single-value {
    color:#fff;
}
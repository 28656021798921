.teaser-slider__btn {
    position: absolute;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.teaser-slider__btn.scrolling-row__button--next {
    right:0;
    top: 50%;
    transform: translateY(-50%) translateX(58%);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.teaser-slider__btn.scrolling-row__button--prev {
    left:0;
    top: 50%;
    transform: translateY(-50%) translateX(-58%) rotate(180deg);
    z-index: 0;
    padding-left: calc(15rem/16);
}
.teaser-slider {
    position: relative;
    @media screen and (max-width: 1499px) and (min-width: 768px){
        margin-left: calc(30rem/16);
        margin-right: calc(30rem/16);
    }
}
.teaser-slider__btn.scrolling-row__button--disabled {
    opacity:0;
}
/* for shadow */
.teaser-slider__container {
    padding-bottom: calc(50rem/16);
    margin-bottom: calc(-50rem/16);
    @media screen and (max-width: 767px) {
        margin-right: calc(-12rem/16);
    }
}
.teaser-slider .img-teaser, .teaser-slider .tour-teaser {
    z-index: 1;
}
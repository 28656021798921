@media screen and (min-width:1700px) {
    .mega-nav--level-1 {
        padding: calc(120rem/16) calc(96rem/16);
    }
    .mega-nav__title--level-1 {
        letter-spacing: calc(6.5rem/16);
        font-size: calc(26rem/16);
    }
}

@media screen and (min-width: 1200px){
    .mega-nav--level-1{
        left: 42%;
    }
}
@media screen and (min-width: 768px) {
    .mega-nav--level-1 {
        color: #fff;
        z-index: 10;
        position: absolute;
        right: 0;
        visibility: hidden;
        opacity: 0;
        min-width: 20rem;
        width: 50%;
        left: 46%;
        top:0;
        height: 100%;
        background-color: rgba(32, 32, 32, 0.95);
        transform: translateX(-50px);
        transition: transform 300ms ease, visibility 0.001ms 300ms, opacity 300ms ease;
        padding: calc(60rem/16) calc(70rem/16);
    }
    .mega-nav__item-content--level-1:hover .mega-nav__collapse-icon {
        opacity: 1;
        color:#fff;
    }
    .mega-nav__list--level-1 {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 50vh;
        min-height: 50vh;
    }
    .mega-nav__item.is-open > .mega-nav--level-1,
    .mega-nav__item.is-open--only-desktop > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        transition: transform 300ms ease, visibility 300ms 0.001ms, opacity 300ms ease;
    }
    .mega-nav--level-1__go-back {
        display:none;
    }
    .mega-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        font-family: var(--font-decorative);
        color: var(--color-primary-light);
        letter-spacing: calc(4.5rem/16);
        font-size: calc(22rem/16);
        text-transform: uppercase;
        margin-bottom: calc(5rem/16);
    }
    .mega-nav__title-link--level-1,
    .mega-nav__item-content--level-1 {
        padding: calc(5rem/16) 0;
        display: block;
    }
    .mega-nav__item-content--level-1 {
        display: block;
        font-size: calc(14rem/16);
        text-transform: uppercase;
        letter-spacing: calc(3.5rem/16);
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-family: var(--font-decorative);
        color:#fff;
    }
    .mega-nav__item--level-1 {
        display: flex;
        flex-grow: 1;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    .mega-nav__title-link--level-1:hover,
    .mega-nav__title-link--level-1:focus,
    .mega-nav__title-link--level-1:active,
    .mega-nav__item-content--level-1:hover,
    .mega-nav__item-content--level-1:focus,
    .mega-nav__item-content--level-1:active {
        color: #fff;
        outline:none;
    }
}

.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.has-danger .form-control {
    border-color: var(--color-danger);
}
.pristine-error {
    color: var(--color-danger);
}
.form-group .pristine-error {
    margin-top: calc(6rem/16);
    margin-left: calc(25rem/16);
}
.info-icons-row {
    display: flex;
    justify-content: center;
    padding: calc(11rem/16) 0 calc(41rem/16);
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    flex-wrap: wrap;
}
.info-icons-row__item {
    color: var(--color-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: calc(74rem/16);
    min-width: calc(160rem/16);
    margin-top: calc(30rem/16);
    margin-right:calc(10rem/16);
    margin-left:calc(10rem/16);
    @media screen and (max-width: 767px) {
        min-width: calc(110rem/16);
    }
}
.info-icons-row__icon {
    font-size: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}
.info-icons-row__text {
    font-family: var(--font-decorative);
    font-size: calc(18rem/16);
    line-height: calc(27rem/16);
    margin-top: calc(15rem/16);
    white-space:nowrap;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: calc(15rem/16);
        margin-top: calc(10rem/16);
    }
}
.simple-text-teaser--default {background-color: var(--color-primary);}
.simple-text-teaser--green {background-color: var(--color-green);}
.simple-text-teaser--yellow {background-color: var(--color-yellow);}
.simple-text-teaser--purple {background-color: var(--color-purple);}
.simple-text-teaser--red {background-color: var(--color-red);}
.simple-text-teaser--blue {background-color: var(--color-blue);}

.simple-text-teaser {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items:center;
    padding: calc(20rem/16);
    min-height: calc(280rem/16);
    overflow: hidden;
}
.simple-text-teaser--small {
    padding: 0 0 calc(30rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.simple-text-teaser--has-image .simple-text-teaser__image {
    position: absolute;
    top:0;
    width: 100%;
}
.simple-text-teaser__image img {
    object-fit:cover;
}
.simple-text-teaser__embed {
    padding-top: calc( 521 / 330 * 100%);
}
.simple-text-teaser__content {
    color:#fff;
    position: relative;
    z-index: 3;
    padding: calc(35rem/16) calc(25rem/16) calc(20rem/16);
    text-align:center;
    font-family: var(--font-decorative);
    letter-spacing: 3px;
    font-size: calc(18rem/16);
    line-height: calc(22/18);
    text-transform: uppercase;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(12rem/16);
        letter-spacing: 1px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        padding: calc(0rem/16) calc(15rem/16) calc(80rem/16);
        overflow: hidden;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        letter-spacing: 1px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
        padding: 0 calc(10rem/16);
    }
}
.simple-text-teaser--small .simple-text-teaser__content {
    font-size: calc(14rem/16);
    letter-spacing: 2px;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        -webkit-line-clamp: 5;
    }
    @media screen and (max-width: 767px) {
        -webkit-line-clamp: 5;
        font-size: calc(11rem/16);
        letter-spacing: calc(0.4rem/16);
    }
}
.simple-text-teaser__icon {
    display: flex;
    border: 2px solid #fff;
    font-size: calc(40rem/16);
    padding: calc(15rem/16);
    border-radius: 50%;
    justify-content: center;
    width: calc(80rem/16);
    height: calc(80rem/16);
    align-items: center;
    margin: 0 auto calc(30rem/16);
    @media screen and (max-width: 1599px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(26rem/16);
        margin: 0 auto calc(20rem/16);
    }
}
.simple-text-teaser__btn {
    z-index: 9;
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
.simple-text-teaser--small .simple-text-teaser__btn {
    @media screen and (max-width: 767px) {
        padding: calc(3rem/16);
        font-size: calc(11rem/16);
        letter-spacing:0;
    }
}
.simple-text-teaser:before {
    content:'';
    display:block;
    position:absolute;
    left:0;
    right:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(249, 243, 255, 0.2);
    opacity:1;
    pointer-events: none;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.3, 1);
    z-index: 1;
}

.simple-text-teaser--has-image:before {
    background-color: var(--color-secondary);
    opacity: 0.3;
    @media screen and (min-width: 768px) {
        opacity: 0.5;
    }
}
@media screen and (min-width: 768px) {
    .simple-text-teaser:hover:before {
        opacity: 0;
    }
    .simple-text-teaser--has-image:before:before {
        opacity: 0.3;
    }
}
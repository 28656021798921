.breadcrumb-container {
    padding-top: calc(60rem / 16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(120rem / 16);
        margin-bottom: calc(60rem/16);
    }
}

.no-hero:not(.is-no-subregion) .breadcrumb-container{
    padding-top: calc(103rem/16);

    @media screen and (min-width: 768px){
       padding-top: calc(165rem/16);
    }
}

.hero ~ .breadcrumb-container {
    padding-top: 0;
}

.breadcrumb--footer {
    @media screen and (min-width: 768px) {
        padding-top:0;
    }
    padding-top:0;
    background-color: var(--color-secondary-light);
    color:#fff;
}
.breadcrumb--footer .breadcrumb {
    margin-bottom:0;
}
.breadcrumb--footer .breadcrumb-item.active {
    color:#fff;
}
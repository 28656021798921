.wysiwyg-with-medium__zoom-btn {
    background-color: var(--color-primary-light);
    width: calc(70rem/16);
    height: calc(70rem/16);
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    z-index: 9;
    left: 50%;
    box-shadow: none;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: none;
    color: #fff;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease;
}
.wysiwyg-with-medium__media {
    position: relative;
}
.wysiwyg-with-medium__media:hover .wysiwyg-with-medium__zoom-btn{
    transform: translate(-50%, -50%) scale(1);
}
.wysiwyg-with-medium__inner {
    padding: calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0 calc(20rem/16);
    }
}
.wysiwyg-with-medium__buttons {
    margin-top: calc(30rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
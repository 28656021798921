.card--package.card {
    box-shadow: none;
}
.card--package .card-body {
    padding: calc(15rem/16);
}
.card--package .text-primary {
    color: var(--color-primary-light) !important;
}
.card--package h4 {
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.card--package .text-muted {
    color: #fff !important;
}
.card--package ul {
    margin-top: calc(15rem/16);
    margin-bottom: calc(15rem/16);
    font-size: calc(12rem/16);
}
.card--package .hr--border-gray {
    border-color:#fff;
}

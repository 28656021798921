.custom-modal__container--xl {
    width: calc(1420rem/16);
    max-width: 95%;
}
.custom-modal__container {
    background: linear-gradient(180deg, #202020 0%, rgba(32,32,32,0.95) 100%);
    color:#fff;
    padding: calc(30rem/16) calc(20rem/16) calc(45rem/16);
}
.custom-modal--white .custom-modal__container {
    background: #fff;
    color: var(--color-text-default);
}
.custom-modal--fixed-height {
    min-height: calc(500rem/16);
}
.custom-modal__content {
     background: transparent;
     max-width: calc(560rem/16);
     margin: 0 auto;
     padding: 0;
     border: none;
 }
.custom-modal__content--wide {
    background: transparent;
    max-width: calc(820rem/16);
    margin: 0 auto;
    padding: 0;
    border: none;
}
.custom-modal__close.close {
    color:#fff;
    box-shadow: none;
    float: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:1;
    font-size: calc(12rem/16);
    font-weight: 100;
    cursor:pointer;
}
.custom-modal--white .custom-modal__close.close {
    color: var(--color-primary-dark);
}
.custom-modal__header--big .custom-modal__close.close {
    font-size: calc(20rem/16);
}
.custom-modal__close-text {
    color:#fff;
    margin-left: calc(20rem/16);
    opacity: 0.2;
    letter-spacing: 3.5px;
    font-size: calc(11rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
}
.custom-modal__header--big .custom-modal__close-text {
    font-size: calc(14rem/16);
}
.custom-modal--white .custom-modal__close-text {
    color: var(--color-primary-light);
}
.custom-modal__sub-title {
    font-size: calc(14rem/16);
    margin-bottom: calc(10rem/16);
}
.custom-modal__body {
    margin-top: calc(40rem/16);
    padding:0;
    @media screen and (max-width: 767px) {
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
    }
}
.custom-modal__header--is-absolute-md {
    @media screen and (min-width: 768px) {
        position: absolute;
        left:0;
        right:0;
        top:0;
        padding: calc(25rem/16);
    }
}

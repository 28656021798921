.logo-overlay {
    position: absolute;
    left:0;
    right:0;
    width: 100%;
    top:0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(12rem/16);
}
.logo-overlay__brand-wrapper {
    padding: calc(10rem/16);
    pointer-events: all;
}
.logo-overlay__brand {
    @media screen and (max-width: 767px) {
        max-width: calc(90rem/16);
    }
}
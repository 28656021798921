.img-text-slide__img-wrapper {
    padding-top: calc(60rem/16);
    padding-bottom: calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding-top:0;
        padding-bottom: 0;
        margin-top: calc(-70rem/16);
    }
}
.img-text-slide__img {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}
.img-text-slide__img:after {
    content:'';
    position: absolute;
    width: 100%;
    opacity:0.5;
    background: linear-gradient(90deg, rgba(32,32,32,0) 0%, rgba(32,32,32,0.5) 100%);
    height: 100%;
    top:0;
}
.img-text-slide__content {
    height: 100%;
    background-color: var(--color-secondary);
    color:#fff;
    padding: calc(60rem/16);
    @media screen and (max-width: 1599px) {
        padding: calc(30rem/16);
    }
    @media screen and (max-width: 767px) {
        max-width: 90%;
        margin: 0 auto;
        position:relative;
        z-index:1;
    }
}
.img-text-slide__wysiwyg {
    padding-top: calc(50rem/16);
    padding-bottom: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
        padding-bottom: calc(20rem/16);
    }
}
.img-text-slide__wysiwyg a, .img-text-slide__wysiwyg a:hover {
    color: var(--color-primary-light);
}
.img-text-slide__title {
    padding-top: calc(50rem/16);
    transform: translateX(calc(-155rem/16));
    word-break: unset;

    @media screen and (min-width: 1599px) {
        width: calc(100% + 120px);
    }
    @media screen and (min-width: 768px){
        width: calc(100% + 60px);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        transform: none;
        padding-top: calc(20rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(30rem/16);
        transform: translateX(calc(-80rem/16));
    }
}
.img-text-slide--move-up {
    position: relative;
    z-index: 3;
    margin-top: calc(-120rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(-10rem/16);
    }
}
.img-text-slide__caption {
    position: absolute;
    font-size: calc(10rem/16);
    color:#fff;
    bottom:0;
    left:0;
    padding: calc(5rem/16);
    text-shadow:0 calc(2rem/16) calc(4rem/16) rgba(0,0,0,0.3);
}
.img-text-slide__title--video{
    z-index: 6;
    position: relative;
}
.img-text-slide__content--video{
    z-index: 3;
}
.img-text-slide--video .consent-overlay.show ~ .video-area__lightbox-toggle {
    display: none;
}
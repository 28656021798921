.slide {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(50rem/16);
        padding-bottom: calc(80rem/16);
    }
}
.slide--big {
    @media screen and (min-width: 768px) {
        padding-top: calc(80rem/16);
        padding-bottom: calc(80rem/16);
    }
}

.slide--has-bg {
    position: relative;
    overflow: hidden;
}
.slide__bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    min-height: calc(760rem/16);
    @media screen and (max-width: 767px) {
        min-height: calc(570rem/16);
    }
}
.slide__bg--gradient {
    max-height: 100vh;
}
.slide__bg--gradient:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(180deg, rgba(247,243,239,0) 0%, var(--body-bg-color) 100%);
}
.slide__bg--light:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: linear-gradient(0deg, rgba(238,238,238,0.5) 0%, var(--body-bg-color) 100%);
    width: 100%;
    height: 100%;
}

:root {
    --color-primary: #003610;
    --color-secondary: #202020;
    --color-text-default: #202020;
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #E4E4E4;
    --color-dark-grey: #898989;
    --color-grey: #D8D8D8;
    --color-dark: #444444;
    --color-dark-bg:#151414;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-yellow:#E3A314;
    --color-blue:#148EBE;
    --color-purple:#535775;
    --color-green:#A2BB0C;
    --color-red:#B3403F;

    --color-yellow-dark: #b48110;
    --color-blue-dark: #0f6c90;
    --color-purple-dark: #393c51;
    --color-green-dark: #7b8e09;
    --color-red-dark: #822e2e;

    --color-primary-contrast: #FFF;
    --color-primary-light-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #001807;
    --color-primary-dark-light: #02682c;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #003610;
    --color-dark-dark: #fff;
    --color-white-dark: #202020;
    --color-default-dark: #1f232a;

    --color-primary-light: #028337;
    --color-primary-light-middle: #02953f;
    --color-primary-light-light: #03b54c;
    --color-secondary-light: #3C3C3C;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --body-bg-color: #F7F3EF;

    --font-default: "Oxygen-Regular", serif;
    --font-default-bold: "Oxygen-Bold", Helvetica, serif;
    --font-decorative: "JosefinSans-Bold", serif;

    --font-weight-default: 400;
    --font-weight-bold: 400;
    --font-weight-decorative: 400;

    --font-size-default: calc(15rem/16);
    --font-size-default--mobile: calc(12rem/16);
    --gutter: 1.75rem;
    --gutter-mobile: 0.75rem;

    --main-navbar-height--xs: calc(60rem/16);
}

.region-slide-wrapper{
    @media screen and (max-width: 767px){
        padding-bottom: calc(154rem/16);
        position: relative;
    }
}

.region-slide-wrapper:before{
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        height: calc(147rem/16);
        bottom: calc(-1rem/16);
        left: 0;
        right: 0;
        background-color: var(--color-primary);
    }
}

.region-slide:before {
    position: absolute;
    width: calc(342rem/16);
    box-shadow: 0 2px 40px 0 rgba(0,0,0,0.5);
    background-color: var(--color-secondary);
    content:'';
    left:0;
    right: 0;
    height: 100%;
    margin: 0 auto;
    top:0;
    z-index: 3;
    @media screen and (max-width: 767px) {
        width: calc(217rem/16);
        height: 106%;
    }
}
.region-slide {
    height: calc(830rem/16);
    position: relative;
    display: flex;
    margin-bottom: calc(-50rem/16);
    @media screen and (max-width: 767px) {
        height: calc(490rem/16);
        margin-bottom: calc(-10rem/16);
    }
}
.region-slide__title-wrapper {
    padding-top: calc(10rem/16);
    width: calc(342rem/16);
    margin: 0 auto;
    z-index: 9;
    color:#fff;
    position: relative;
    text-align: center;
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    margin-top: calc(-60rem/16);
    @media screen and (max-width: 767px) {
        width: calc(217rem/16);
        margin-top: calc(-30rem/16);
    }
}
.region-slide__title {
    color: var(--color-primary-light);
    font-size: calc(20rem/16);
    margin-bottom: calc(20rem/16);
}
.region-slide__inner {
    margin-top: calc(100rem/16);
    width: 100%;
    height: calc(680rem/16);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(435rem/16);
        background-image: var(--image-src) !important;
        margin-top: calc(55rem/16);
    }
    @media screen and (min-width: 768px) {
        background-image: var(--image-src) !important;
    }
}
.region-slide__inner-body:before {
    width: 40%;
    height: 100%;
    content:'';
    position: absolute;
    left:0;
    top:calc(60rem/16);
    z-index: 10;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(32,32,32,0) 0%, #202020 100%);
    @media screen and (max-width: 767px) {
        width: 10%;
        top:calc(30rem/16);
    }
}
.region-slide__inner-body:after {
    width: 40%;
    height: 100%;
    content:'';
    position: absolute;
    right:0;
    top:calc(60rem/16);
    z-index: 10;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(32,32,32,0) 0%, #202020 100%);
    @media screen and (max-width: 767px) {
        width: 10%;
        top:calc(30rem/16);
    }
}
.region-slide__inner:before {
    background-color: var(--color-secondary);
    height: 100%;
    content:'';
    position: absolute;
    left:0;
    top: 0;
    bottom:0;
    width: 100%;
    opacity: 0.7;
    pointer-events: none;
}
.region-slide__inner-body {
    position: relative;
    z-index: 9;
    height: 100%;
}
.region-slide__content {
    width: calc(342rem/16);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: calc(217rem/16);
    }
}
.region-slide__btn {
    min-width: calc(155rem/16);
}
.region-slide__map {
    @media screen and (max-width: 1199px) and (min-width: 768px){
        max-width: calc(380rem/16);
    }
    @media screen and (max-width: 767px) {
        max-width: calc(240rem/16);
    }
}
.region-slide__link {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary-light);
    transition: color 0.2s ease;
}
.region-slide__link:hover {
    color:#fff;
}
.region-slide__map{
    height: auto;
}
.triangle-badge {
    width: calc(132rem/16);
    height: calc(132rem/16);
    position: absolute;
    z-index: 2;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        width: calc(100rem/16);
        height: calc(100rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(80rem/16);
        height: calc(80rem/16);
    }
}
.triangle-badge--right {
    transform: rotate(45deg);
    top:0;
    right:0;
}
.triangle-badge--left {
    transform: rotate(-45deg);
    top:0;
    left:0;
}
.triangle-badge--left.right-xs {
    @media screen and (max-width: 767px) {
        transform: rotate(45deg);
        top:0;
        left:initial;
        right:0;
    }
}
.triangle-badge--left:before {
    content:'';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(132rem/16) calc(132rem/16) 0 0;
    border-color: var(--color-primary) transparent transparent transparent;
    transform: rotate(45deg);
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.5));
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        border-width: calc(100rem/16) calc(100rem/16) 0 0;
    }
    @media screen and (max-width: 767px) {
        border-width: calc(80rem/16) calc(80rem/16) 0 0;
    }
}
.triangle-badge--right:before {
    content:'';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 calc(132rem/16) calc(132rem/16) 0;
    border-color: transparent var(--color-primary) transparent transparent;
    transform: rotate(-45deg);
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.5));
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        border-width: 0 calc(100rem/16) calc(100rem/16) 0;
    }
    @media screen and (max-width: 767px) {
        border-width: 0 calc(80rem/16) calc(80rem/16) 0;
    }
}
.triangle-badge__text {
    color: #fff;
    position: absolute;
    text-align: center;
    width: 100%;
    transform-origin: center;
    bottom: 50%;
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: 1.75px;
    text-transform: uppercase;
    transform: translateY(-20%);
    @media screen and (min-width: 768px) and (max-width: 1399px) {
        font-size: calc(11rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(9rem/16);
        letter-spacing: 1px;
    }
}

.rating__icon {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.rating__icon:not(:last-child) {
    margin-right: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(4rem / 16);
    }
}